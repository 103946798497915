import { db } from '@/firebase'

export default {
    getDirectorio({ rootState, commit }) {
        if (!!rootState.restauranteObj.directorioMatriz) {
            commit('setDirectorio', rootState.restauranteObj.directorioMatriz)
            return db.collection('directorios').doc(rootState.restauranteObj.directorioMatriz).get()
                .then(d => {
                    if (d.exists) {
                        let data = {
                            ...d.data(),
                            id: d.id
                        }
                        commit('setDirectorioObj', data)
                        return;
                    }
                })
        } else {
            return Promise.resolve()
        }
    }
}