<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="pedidos"
          item-key="name"
          :loading="loading"
          :search="search"
          sort-by="numPedidoInternos"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.estado="{ item }">{{
            item.estado.text
          }}</template>
          <template v-slot:item.acciones="{ item }">
            <td v-if="item.estado.value == 'realizando'">
              <v-row class="d-flex flex-row-reverse">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="px-2 ml-1"
                      color="primary"
                      min-width="0"
                      icon
                      v-on="on"
                      @click="goAdd(item)"
                    >
                      <v-icon big>mdi-arrow-expand-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Continuar</span>
                </v-tooltip>
              </v-row>
            </td>
            <td v-else>
              <v-row class="d-flex flex-row-reverse">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="px-2 ml-1"
                      color="black"
                      min-width="0"
                      icon
                      v-on="on"
                      @click="goView(item)"
                    >
                      <v-icon big>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver</span>
                </v-tooltip>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      loading: false,
      filters: {
        fechaFilter: "",
        envioFilter: "",
        estadoFilter: "",
        restFilter: "",
      },
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "numPedidoInternos",
          width: "15%",
        },
        { text: "Fecha", value: "fechaEspera", filter: "", width: "25%" },
        { text: "Estado", value: "estado", filter: "", width: "25%" },
        {
          text: "Fecha envio desde la central",
          value: "fechaEnviado",
          filter: "",
          width: "25%",
        },
        {
          sortable: false,
          text: "Acciones",
          value: "acciones",
          align: "right",
          width: "15%",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      //pedidos : (state) => state.internos.pedidos
    }),
    ...mapGetters({
      pedidos: "internos/getPedidosListado",
    }),
    search() {
      let res = "";
      return res;
      for (let item in this.filters) {
        res += item;
      }
      return res;
    },
  },
  methods: {
    getPedidos() {
      var _vm = this;
      _vm.loading = true;
      this.$store
        .dispatch("internos/getPedidos")
        .then(() => {})
        .finally(() => {
          _vm.loading = false;
        });
    },
    goAdd(item) {
      this.$router.push("/pedidos-internos/add/" + item.id);
    },
    goView(item) {
      this.$router.push("/pedidos-internos/ver/" + item.id);
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        if (a.estado.value == "realizando") {
          return -1;
        }
        if (isDescending) {
          return moment(b.fechaEspera) - moment(a.fechaEspera);
        } else {
          return moment(a.fechaEspera) - moment(b.fechaEspera);
        }
      });

      return items;
    },
  },
  mounted() {
    this.getPedidos();
  },
};
</script>
	
<style scope>
.border {
  border: 1px solid #000;
}
</style>