import firebase from 'firebase';
import { db } from '@/firebase'

export default {
    firebaseLogin: ({ commit }, user) => {
        //let _vm = this
        return firebase.auth().signInWithEmailAndPassword(user.email, user.password)
            .then((user) => {
                return db.collection('users').doc(user.user.uid).get()
                    .then((doc) => {
                        let user_db = {};
                        if (doc.exists) {
                            user_db = doc.data();
                        }
                        //reducir el objeto user a lo necesario
                        //var array_promesas = [];
                        let user_combinado = { ...user, ...user_db };
                        commit('setUser', user_combinado);
                    })
            })
    },
    firebaseLogout: ({ commit }) => {
        return firebase.auth().signOut()
            .then(() => {
                commit('logout');
                location.reload()
            })
            .catch((e) => {
                console.log(e);
            })
    }
}