import _ from 'lodash'
import moment from 'moment-timezone';
export default {
  //depreciar
  clientesPedidosListado(state, getters, rootState) {
    var merge = _.map(rootState.pedidos.pedidosArray, function (item) {
      let zonaHoraria = (item.copyRest.confGeneral.zonaHoraria) ? item.copyRest.confGeneral.zonaHoraria : "Europe/Madrid"
      return _.merge(
        {
          ...item,
          fechaMostrar: moment(item.fechaServidor.toDate()).tz(zonaHoraria).format('HH:mm DD-MM-YYYY')
        }, _.find(state.clientesArray, { 'uid': item.uid }))
    })
    return merge.sort((a, b) => b.fechaServidor - a.fechaServidor);
  }
}