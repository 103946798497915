// https://vuex.vuejs.org/en/actions.html
import firebase from 'firebase'
import { db } from '@/firebase'
import moment from 'moment'

export default {
  inicioTpvAlternativo({ commit, rootState, state, dispatch }) {
    var p1 = db.collection('restaurantes').doc(rootState.auth.user.restauranteTpv)
      .onSnapshot((r) => {
        if (r.exists) {
          var rest = r.data();
          rest.id = r.id;
          const domicilio = (rest.tiempoDomicilio) ? rest.tiempoDomicilio : 0;
          const recogida = (rest.tiempoRecogida) ? rest.tiempoRecogida : 0;
          const modoHorarioRecoger = (rest.modoHorarioRecoger) ? (rest.modoHorarioRecoger == 'auto') : false;
          const modoHorarioDomicilio = (rest.modoHorarioDomicilio) ? (rest.modoHorarioDomicilio == 'auto') : false;
          const flag = (state.restauranteObj == null);//para llamar a toda la info
          commit('setTiempoDomicilio', domicilio);
          commit('setTiempoRecogida', recogida);
          commit('setRecogidaActivada', modoHorarioRecoger);
          commit('setDomicilioActivado', modoHorarioDomicilio);
          commit('setRestauranteObj', rest);
          if (flag) {
            dispatch('pedidos/getPedidos', {}, { root: true })
          }
        } else {
          commit('setRestauranteObj', false); //no existe restaurante o no tiene 
        }
      })

    var p2 = db.collection('negocios').doc(rootState.auth.user.negocioTpv)
      .onSnapshot((n) => {
        if (n.exists) {
          var neg = n.data();
          neg.id = n.id;
          commit('setNegocioObj', neg);
        }
      })
    var not = dispatch('notificaciones/getNotificaciones');
    var prod = dispatch('productos/getProductos')
    var cat = dispatch('productos/getCategorias')
    var imp = dispatch('getImpuestos')
    return Promise.all([p1, p2, not, prod, imp]).then(() => {
      return
    })
      .catch(() => { commit('setRestauranteObj', false) })
  },
  cargaDespuesInicio({ dispatch, rootState }) {
    return Promise.all([
      dispatch('internos/getCategorias', {}, { root: true }),
      dispatch('internos/getProductos', {}, { root: true }),
      dispatch('directorio/getDirectorio', {}, { root: true })
    ])
      .then(() => {
        if (rootState.directorio.directorioMatriz)
          dispatch('pedidos/getPedidosDirectorioMatriz', {}, { root: true })
      })
      .catch(e => { console.log(e) })
  },
  inicioTpv({ commit }) {
    var f = firebase.functions().httpsCallable('httpTpvCargaInicial');
    return f().then((r) => {
      const rest = r.data[0];
      const domicilio = (rest.tiempoDomicilio) ? rest.tiempoDomicilio : 0;
      const recogida = (rest.tiempoRecogida) ? rest.tiempoRecogida : 0;
      const modoHorarioRecoger = (rest.modoHorarioRecoger) ? (rest.modoHorarioRecoger == 'auto') : false;
      const modoHorarioDomicilio = (rest.modoHorarioDomicilio) ? (rest.modoHorarioDomicilio == 'auto') : false;
      commit('setRestauranteObj', r.data[0]);
      commit('setNegocioObj', r.data[1]);
      commit('setTiempoDomicilio', domicilio);
      commit('setTiempoRecogida', recogida);
      commit('setRecogidaActivada', modoHorarioRecoger);
      commit('setDomicilioActivado', modoHorarioDomicilio);
    })
  },
  setTiempoDomicilio({ rootState, state, commit }, t) {
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          tiempoDomicilio: t
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setTiempoDomicilio', t) })

    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        tiempoDomicilio: t
      }, { merge: true })
        .then(() => { commit('setTiempoDomicilio', t) })
    }
  },
  setTiempoRecogida({ state, commit, rootState }, t) {
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          tiempoRecogida: t
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setTiempoRecogida', t) })

    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        tiempoRecogida: t
      }, { merge: true })
        .then(() => { commit('setTiempoRecogida', t) })
    }

  },
  setRecogidaActivada({ rootState, state, commit }, { v, t }) {
    let tiempo = (t) ? moment().unix() + (t * 60) : null
    const modoHorarioRecoger = (v) ? 'auto' : 'forzado_cierre';

    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          modoHorarioRecoger: modoHorarioRecoger,
          modoHorarioRecogerTiempo: tiempo
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setRecogidaActivada', t) })
    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        modoHorarioRecoger: modoHorarioRecoger,
        modoHorarioRecogerTiempo: tiempo
      }, { merge: true })
        .then(() => { commit('setRecogidaActivada', t) })
    }
  },
  setDomicilioActivado({ rootState, state, commit }, { v, t }) {
    let tiempo = (t) ? moment().unix() + (t * 60) : null
    const modoHorarioDomicilio = (v) ? 'auto' : 'forzado_cierre';
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(rootState.directorio.directorioMatrizObj.restaurantes.map(r => {
        return db.collection('restaurantes').doc(r).set({
          modoHorarioDomicilio: modoHorarioDomicilio,
          modoHorarioDomicilioTiempo: tiempo
        }, { merge: true })
          .catch(e => console.log(e))
      }))
        .then(() => { commit('setDomicilioActivado', t) })
    } else {
      return db.collection('restaurantes').doc(state.restauranteObj.id).set({
        modoHorarioDomicilio: modoHorarioDomicilio,
        modoHorarioDomicilioTiempo: tiempo

      }, { merge: true })
        .then(() => { commit('setDomicilioActivado', t) })
    }
  },
  imprimirz({ state }) {
    var f = firebase.functions().httpsCallable('httpTpvImprimirZcierre');
    return f(state.restauranteObj.id).then((r) => { return r.data })
  },
  getImpuestos({ commit }) {
    let devolver = [];
    return db.collection('impuestos').get()
      .then((res) => {
        res.forEach(doc => {
          let aux = doc.data();
          aux.id = doc.id;
          devolver.push(aux);
        });

        commit('setImpuestos', devolver)
      })
  },

}
