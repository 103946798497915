export default {
  pedidos: [],
  categorias: [],
  productos: [],
  pedidoEnCurso: {
    id: null,
    fechaInicio: null,
    fechaFin: null,
    carritoInterno: []
  },
  pedidoGuardado: true,
  pedidoGuardadoLoading: false,
  pedidoEnviado: false,
  pedidoEnviarLoading: false,
  pedidoEnCursoComprobar: {
    id: null,
    fechaInicio: null,
    fechaFin: null,
    carritoInterno: []
  },
  estados: [
    {
      value: 'realizando',
      text: 'Realizando el franquiciado'
    },
    {
      value: 'espera',
      text: 'En espera'
    },
    {
      value: 'enviado',
      text: 'Enviado por la central'
    },
    {
      value: 'finalizado',
      text: 'Finalizado'
    },
  ],
}