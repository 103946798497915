<template>
	<v-content>
		<v-row>
			<v-col cols="12">
				<v-data-table
				:headers="headers"
				:items="clientes"
				:custom-filter="customFilter"
				:search="search">
					<template v-slot:top>
						<tr>

							<th width="25%" class="pa-2">
								<v-text-field
									dense
									class="customInput"
									v-model="filters.nombreFilter"
									placeholder="Buscar..."
									hide-details
									outlined
									single-line
								></v-text-field>
							</th>
							<th width="25%" class="pa-2">
								<v-text-field
									dense
									class="customInput"
									v-model="filters.telefonoFilter"
									placeholder="Buscar..."
									hide-details
									outlined
									single-line
								></v-text-field
									dense
									class="customInput">
							</th>
							<th width="25%" class="pa-2">
								<v-text-field
									dense
									class="customInput"
									v-model="filters.emailFilter"
									placeholder="Buscar..."
									hide-details
									outlined
									single-line
								></v-text-field>
							</th>
							<th width="25%" class="pa-2">

							</th>

						</tr>
					</template>
					<template v-slot:item.acciones="{ item }">
						<v-row class="d-flex flex-row-reverse">
							<v-tooltip top>
								<template v-slot:activator="{ on }">

									<v-btn
										class="px-2 ml-1"
										color="black"
										min-width="0"
										icon
										v-on="on"
										@click="contactar(item)"
									>
										<v-icon>mdi-phone</v-icon>
									</v-btn>
								</template>
								<span>Contactar</span>

							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on }">

									<v-btn
										class="px-2 ml-1"
										color="black"
										min-width="0"
										icon
										v-on="on"
										@click="direcciones(item)"
									>
										<v-icon>mdi-home</v-icon>
									</v-btn>

								</template>
								<span>Direcciones</span>
							</v-tooltip>
						</v-row>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-content>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
	data() {
		return {
			headers: [
				{ text: "Nombre", value: "displayName", filter: "", width: "25%" },
				{ text: "Teléfono", value: "phoneNumber", filter: "", width: "25%" },
				{ text: "Email", value: "email", filter: "", width: "25%" },
				{
					sortable: false,
					text: "Acciones",
					value: "acciones",
					align: "right",
					width: "25%"
				}
			],
			filters: {
				nombreFilter: "",
				telefonoFilter: "",
				emailFilter: ""
			}
		};
	},
	computed: {
		...mapState({}),
		...mapGetters({
			clientes: "clientes/clientesPedidosListado"
		}),
		search() {
			let res = "";
			for (let item in this.filters) {
				res += item;
			}
			return res;
		}
	},
	methods: {
		...mapActions({
		}),
		contactar() {},
		direcciones(item) {
		},
		customFilter(value, search, item) {
			let nombre = item.nombre;
			let telefono = item.telefono;
			let email = item.email;

			if (typeof nombre != "String") nombre = nombre.toString().toLowerCase();
			if (typeof telefono != "String") telefono = telefono.toString().toLowerCase();
			if (typeof email != "String") email = email.toString().toLowerCase();

			return (
				nombre.includes(this.filters.nombreFilter.toString().toLowerCase()) &&
				telefono.includes(this.filters.telefono.toString().toLowerCase()) &&
				email.includes(this.filters.email.toString().toLowerCase())
			);
		},
	}
};
</script>

<style>
</style>