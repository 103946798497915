<template>
  <v-row>
    <v-col cols="6" md="4" lg="3" class @click="$router.push('/pedidos')">
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-list-item-content>
            <div class="text-center">
              <v-btn absolute outlined dark x-small fab right color="pink">
                <h3>{{ $store.getters["pedidos/numPedidos"].toString() }}</h3>
              </v-btn>

              <v-icon
                class="my-4"
                color="teal darken-4"
                :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-format-list-bulleted</v-icon
              >
            </div>
          </v-list-item-content>

          <v-divider></v-divider>

          <v-card-actions class="grey lighten-4">
            <span
              class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
              >Pedidos</span
            >
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col>
    <!-- <v-col cols="6" md="4" lg="3" class @click="$router.push('/productos')">
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-list-item-content>
            <div class="text-center">
              <v-icon
                class="my-4"
                color="teal darken-4"
                :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-food</v-icon
              >
            </div>
          </v-list-item-content>

          <v-divider></v-divider>

          <v-card-actions class="grey lighten-4">
            <span
              class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
              >Productos</span
            >
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col> -->
    <v-col
      cols="6"
      md="4"
      lg="3"
      class
      @click="$router.push('/pedidos-internos')"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-list-item-content>
            <div class="text-center">
              <v-icon
                class="my-4"
                color="teal darken-4"
                :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-cart-outline</v-icon
              >
            </div>
          </v-list-item-content>

          <v-divider></v-divider>

          <v-card-actions class="grey lighten-4">
            <span
              class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
              >Pedidos Internos</span
            >
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col>
    <v-col
      cols="6"
      md="4"
      lg="3"
      class
      @click="$router.push('/notificaciones')"
    >
      <v-hover v-slot:default="{ hover }">
        <v-card
          elevation="4"
          outlined
          class
          :style="hover ? 'cursor: pointer' : ''"
          :color="hover ? 'grey lighten-4' : 'white'"
        >
          <v-list-item-content>
            <div class="text-center">
              <v-icon
                class="my-4"
                color="teal darken-4"
                :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                >mdi-bullhorn-outline</v-icon
              >
            </div>
          </v-list-item-content>

          <v-divider></v-divider>

          <v-card-actions class="grey lighten-4">
            <span
              class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
              >Notificaciones</span
            >
          </v-card-actions>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      isLoaded: (state) => state.loaded,
    }),
  },
  methods: {
    // ...mapActions({
    // }),
    cargaInicial() {
      //this.$store.dispatch("inicioTpv");
    },
    btnCuadrado(e) {
      let res =
        document.getElementsByClassName("mainBtn")[e] == undefined
          ? 200
          : document.getElementsByClassName("mainBtn")[e]["clientWidth"];
      return res;
    },
  },
  mounted() {},
};
</script>