const estados = [
  {
    text: 'Preparando',
    value: 'preparando'
  },
  {
    text: 'En reparto',
    value: 'reparto'
  },
  {
    text: 'Finalizado',
    value: 'finalizado'
  },
  {
    text: 'Cancelado',
    value: 'cancelado'
  },

]
export { estados }