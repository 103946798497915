import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Tpv from '../views/Tpv.vue'
import Portada from '../views/Portada.vue'
import Pedidos from '../views/Pedidos.vue'
import PedidosForm from '../views/PedidosForm.vue'
import Clientes from '../views/Clientes.vue'
import Productos from '../views/Productos.vue'
import PedidosInternos from '../views/PedidosInternos.vue'
import PedidosInternosForm from '../views/PedidosInternosForm.vue'
import GestionInterna from '../views/GestionInterna.vue'
import Notificaciones from '../views/Notificaciones.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'TPV',
    component: Tpv,
    children: [
      {
        path: '/dashboard',
        name: 'Portada TPV',
        component: Portada
      },
      {
        path: '/pedidos',
        name: 'Pedidos',
        component: Pedidos
      },
      {
        path: '/pedido/:idpedido',
        name: 'Editar Pedido',
        component: PedidosForm
      },
      {
        path: '/clientes',
        name: 'Clientes',
        component: Clientes
      },
      {
        path: '/productos',
        name: 'Productos',
        component: Productos
      },
      {
        path: '/pedidos-internos',
        name: 'Pedidos Internos',
        component: PedidosInternos
      },
      {
        path: '/pedidos-internos/:accion',
        name: 'Añadir Pedido Interno',
        component: PedidosInternosForm
      },
      {
        path: '/pedidos-internos/:accion/:idpedido',
        name: 'Pedido Interno',
        component: PedidosInternosForm
      },

      {
        path: '/notificaciones',
        name: 'Listado notificaciones recibidas',
        component: Notificaciones
      },
      {
        path: '/gestion-interna',
        name: 'Gestion Interna',
        component: GestionInterna
      }
    ]
  },
  {
    path: '/login',
    name: 'Fun Eats acceso TPV',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.loaded) {
    store.watch(
      () => store.getters.isLoaded,
      (value) => {
        if (value === true) {
          proceed(to, from, next);
        }
      }
    )
  } else {
    proceed(to, from, next);
  }

  function proceed(to, from, next) {
    document.title = to.name;
    if (to.meta.logged && !store.state.auth.isLogged) {
      next({ path: '/login' });
    } else if (to == '/') {
      next({ path: '/dashboard' })
    } else {
      next();
    }
  }

})

export default router
