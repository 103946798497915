<template>
  <v-dialog v-model="addProducto">
    <v-card>
      <v-toolbar dark>
        <v-btn icon v-if="pasoPidiendo != 0" @click="pasoPidiendo--">
          <v-icon class="white--text">mdi-arrow-left-bold</v-icon>
        </v-btn>

        <v-toolbar-title>Añadir Producto</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>

      <template v-if="pasoPidiendo == 0">
        <v-card-text>Categorías</v-card-text>
        <v-row class="px-3">
          <v-col cols="12" md="3" v-for="c in categorias" :key="c.id">
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="elegirCategoria(c)"
                elevation="4"
                outlined
                class
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'grey lighten-4' : 'white'"
              >
                <v-list-item-content>
                  <div class="text-center">
                    <v-btn
                      absolute
                      outlined
                      dark
                      x-small
                      fab
                      right
                      color="pink"
                    >
                      <h3>{{ c.cantidad }}</h3>
                    </v-btn>

                    <v-icon
                      class="my-4"
                      color="teal darken-4"
                      :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                      >mdi-food</v-icon
                    >
                  </div>
                </v-list-item-content>

                <v-divider></v-divider>

                <v-card-actions class="grey lighten-4">
                  <span
                    class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
                    >{{ c.nombre }}</span
                  >
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col></v-row
        >
      </template>
      <template v-else-if="pasoPidiendo == 1">
        <v-card-text
          >Productos de la categoria {{ nombreCategoria }}</v-card-text
        >
        <v-row class="px-3">
          <v-col cols="12" md="3" v-for="p in productos" :key="p.id">
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="elegirProducto(p)"
                elevation="4"
                outlined
                class
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'grey lighten-4' : 'white'"
              >
                <v-list-item-content>
                  <div class="text-center">
                    <v-btn
                      absolute
                      outlined
                      dark
                      x-small
                      fab
                      right
                      color="pink"
                    >
                    </v-btn>

                    <v-icon
                      class="my-4"
                      color="teal darken-4"
                      :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                      >mdi-food</v-icon
                    >
                  </div>
                </v-list-item-content>

                <v-divider></v-divider>

                <v-card-actions class="grey lighten-4">
                  <span
                    class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
                    >{{ p.nombre }}</span
                  >
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </template>
      <template v-else-if="pasoPidiendo == 2">
        <v-card-text>Producto {{ nombreProducto }}</v-card-text>
        <!-- <v-row class="px-3">
          <v-col cols="12" md="3" v-for="p in productos" :key="p.id">
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="elegirProducto(p)"
                elevation="4"
                outlined
                class
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'grey lighten-4' : 'white'"
              >
                <v-list-item-content>
                  <div class="text-center">
                    <v-btn
                      absolute
                      outlined
                      dark
                      x-small
                      fab
                      right
                      color="pink"
                    >
                    </v-btn>

                    <v-icon
                      class="my-4"
                      color="teal darken-4"
                      :size="$vuetify.breakpoint.mdAndDown ? '35' : '45'"
                      >mdi-food</v-icon
                    >
                  </div>
                </v-list-item-content>

                <v-divider></v-divider>

                <v-card-actions class="grey lighten-4">
                  <span
                    class="text-uppercase mx-auto font-weight-bold teal--text text--darken-4 subtitle-2"
                    >{{ p.nombre }}</span
                  >
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-col>
        </v-row> -->
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import { mostrarIdioma } from "@/utils/helper.utils.js";

export default {
  data() {
    return {
      pasoPidiendo: 0,
    };
  },
  watch: {
    addProducto(v) {
      if (!v) {
        setTimeout(() => {
          this.pasoPidiendo = 0;
          this.$store.commit("productos/setCategoriaPidiendo", null);
          this.$store.commit("productos/setProductoPidiendo", null);
        }, 500);
      }
    },
  },
  computed: {
    categorias() {
      return this.$store.state.productos.categorias.map((c) => {
        return {
          ...c,
          nombre: mostrarIdioma(c.nombre),
          cantidad: this.$store.state.productos.productosArray.filter(
            (p) => p.categoria == c.id
          ).length,
        };
      });
    },
    productos() {
      return this.$store.state.productos.productosArray
        .filter((p) => p.categoria == this.categoriaPidiendo.id)
        .map((p) => {
          return {
            ...p,
            nombre: mostrarIdioma(p.nombre),
          };
        });
    },
    nombreCategoria() {
      return this.categoriaPidiendo ? this.categoriaPidiendo.nombre : "";
    },
    nombreProducto() {
      return this.productoPidiendo ? this.productoPidiendo.nombre : "";
    },
    ...mapState({
      productoPidiendo: (state) => state.productos.productoPidiendo,
      categoriaPidiendo: (state) => state.productos.categoriaPidiendo,
    }),
    addProducto: {
      get() {
        return this.$store.state.productos.pidiendoDialog;
      },
      set(v) {
        this.$store.commit("productos/setPidiendoDialog", v);
      },
    },
  },
  methods: {
    elegirCategoria(c) {
      this.$store.commit("productos/setCategoriaPidiendo", c);
      this.pasoPidiendo = 1;
    },
    elegirProducto(p) {
      if (p.opciones && p.opciones.length > 0) {
        this.$store.commit("productos/setProductoPidiendo", p);
        this.pasoPidiendo = 2;
      } else {
        console.log(this.$store.state.pedidos.pedidoEditando);
        // this.$store.commit("productos/setProductoPidiendo", p);
        // this.addProducto = false;
      }
    },
  },
};
</script>

<style>
</style>