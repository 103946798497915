var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"grey lighten-4 pa-4",attrs:{"elevation":"4","outlined":""}},[_c('v-container',{staticClass:"d-flex flex-column pa-0",attrs:{"height":"100%"}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Tu pedido")])]),_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"9"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","overflow-y":""}},[_c('v-card',{ref:"containerCarrito",staticClass:"mx-auto",staticStyle:{"overflow-y":"auto"},attrs:{"width":"100%","height":"320","max-height":"320","id":"containerCarrito","outlined":""}},[_c('v-card-text',{staticClass:"py-1 px-2 cyan accent-1",staticStyle:{"min-height":"100%"}},[_c('v-list',{staticClass:"transparent",attrs:{"dense":"","flat":""}},_vm._l((_vm.carritoInterno),function(prod,i){return _c('v-list-item',{key:i,staticClass:"px-0"},[_c('v-list-item-icon',{staticClass:"mr-0"},[_c('v-list-item-title',[_vm._v(_vm._s(prod.cantidad))])],1),_c('v-list-item-content',{},[_c('v-list-item-title',[_vm._v(_vm._s(prod.nombre))])],1),_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"red lighten-1","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(
                              !_vm.$router.currentRoute.path.includes(
                                '/pedidos-internos/ver'
                              )
                            )?_c('v-btn',_vm._g({staticClass:"mt-n1",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.deleteThis(prod.id)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Borrar")])])],1)],1)}),1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pl-4 pr-0 d-none d-md-flex",attrs:{"cols":"3"}},[_c('v-card',{staticClass:"d-flex align-center justify-center flex-column",attrs:{"flat":"","color":"transparent","width":"100%","height":"100%"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center mb-2",attrs:{"width":"100%","height":"100%","color":hover ? 'grey lighten-4' : 'white'},on:{"mouseup":function($event){_vm.mouseDownToScroll = false},"mousedown":function($event){return _vm.scrollTo('up')}}},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-chevron-up")])],1)]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center mt-2",attrs:{"width":"100%","height":"100%","color":hover ? 'grey lighten-4' : 'white'},on:{"mouseup":function($event){_vm.mouseDownToScroll = false},"mousedown":function($event){return _vm.scrollTo('down')}}},[_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-chevron-down")])],1)]}}])})],1)],1),_c('v-col',{staticClass:"pa-0 mt-4 text-right",attrs:{"cols":"12"}},[_c('span',{staticClass:"caption mb-3"},[_vm._v("(Los precios pueden variar según el pesos)")]),_c('h3',{staticClass:"d-flex mt-4 title"},[_c('span',{staticClass:"ml-auto"},[_vm._v("Total: "+_vm._s(_vm.calculoTotal.toFixed(2))+" €")])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }