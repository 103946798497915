import { db, storage } from '@/firebase'

export default {
  getProductos({ commit, rootState }) {
    let devolver = [];
    let negocio = rootState.auth.user.negocioTpv;
    return db.collection('negocios').doc(negocio).collection('productos').get()
      .then((res) => {
        res.forEach(doc => {
          let aux = doc.data();
          aux.id = doc.id;
          devolver.push(aux);
        });
        return devolver;
      })
      .then((res) => {
        var actions = res.map(function (item) {
          if (item.imagen) {
            return item;
          } else {
            var storageRef = storage.ref('imagenes/productos/' + item.id + '.jpg');
            //var spaceRef = storageRef.child('imagenes/caracteristicas/' + element.id + '.jpg');
            return storageRef.getDownloadURL()
              .then((r) => {
                item.imagen = r;
                return item;
              })
              .catch((e) => {
                item.imagen = false;
                return item;
              })
          }
        });
        return Promise.all(actions)
          .then((r) => {
            commit('setProductosArray', r)
          })
          .catch((e) => {
            console.log(e);
          })
      })
  },
  getCategorias({ commit, rootState }) {
    let negocio = rootState.auth.user.negocioTpv;

    return db.collection('negocios').doc(negocio).collection('categorias')
      .get()
      .then((r) => {
        let res = [];
        let docs = r.docs;
        docs.forEach(e => {
          let d = e.data();
          d.id = e.id;
          res.push(d);
        });
        commit('setCategorias', res)
      });
  }
}