<template>
  <v-content>
    <v-row>
      <v-col cols="12"></v-col>
    </v-row>
  </v-content>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({}),
  },
  methods: {},
  mounted() {},
};
</script>
	
<style scope>
</style>