<template>
  <!-- Menu lateral -->

  <v-card elevation="4" outlined class="grey lighten-4 pa-4">
    <v-container class="d-flex flex-column pa-0" height="100%">
      <v-row class="mx-0">
        <v-col class="pa-0" cols="12">
          <h3>Tu pedido</h3>
        </v-col>
        <v-col class="px-0 py-0" cols="9">
          <v-row>
            <v-col class cols="12" overflow-y>
              <v-card
                class="mx-auto"
                width="100%"
                height="320"
                max-height="320"
                id="containerCarrito"
                ref="containerCarrito"
                outlined
                style="overflow-y: auto"
              >
                <v-card-text
                  class="py-1 px-2 cyan accent-1"
                  style="min-height: 100%"
                >
                  <v-list dense flat class="transparent">
                    <v-list-item
                      v-for="(prod, i) in carritoInterno"
                      :key="i"
                      class="px-0"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-list-item-title>{{
                          prod.cantidad
                        }}</v-list-item-title>
                      </v-list-item-icon>
                      <v-list-item-content class>
                        <v-list-item-title>{{ prod.nombre }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-tooltip color="red lighten-1" top>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-if="
                                !$router.currentRoute.path.includes(
                                  '/pedidos-internos/ver'
                                )
                              "
                              small
                              class="mt-n1"
                              icon
                              v-on="on"
                              @click="deleteThis(prod.id)"
                            >
                              <v-icon color="red">mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Borrar</span>
                        </v-tooltip>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                  <!-- <v-row
                    class="py-3"
                    justify="center"
                    no-gutters
                    v-for="(prod,i) in carritoInterno"
                    :key="i"
                  >
                    <v-col
                      class="pa-0 ma-0 d-flex align-center justify-center border"
                      cols="2"
                      style="max-width:54px;"
                    >{{prod.cantidad}}</v-col>
                    <v-col cols class="pl-2 py-0 text-truncate d-flex align-center">
                      <v-list-item-title>{{ prod.nombre }}</v-list-item-title>
                    </v-col>
                    <v-col cols="2" class style="max-width:76px">
                      <v-tooltip color="red lighten-1" top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="px-2 ml-1"
                            color="red lighten-1"
                            icon
                            v-on="on"
                            @click="deleteThis(prod.id)"
                          >
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Borrar</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>-->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pl-4 pr-0 d-none d-md-flex" cols="3">
          <v-card
            flat
            color="transparent"
            class="d-flex align-center justify-center flex-column"
            width="100%"
            height="100%"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="d-flex align-center justify-center mb-2"
                width="100%"
                height="100%"
                :color="hover ? 'grey lighten-4' : 'white'"
                @mouseup="mouseDownToScroll = false"
                @mousedown="scrollTo('up')"
              >
                <v-icon size="40">mdi-chevron-up</v-icon>
              </v-card>
            </v-hover>
            <v-hover v-slot:default="{ hover }">
              <v-card
                class="d-flex align-center justify-center mt-2"
                width="100%"
                height="100%"
                :color="hover ? 'grey lighten-4' : 'white'"
                @mouseup="mouseDownToScroll = false"
                @mousedown="scrollTo('down')"
              >
                <v-icon size="40">mdi-chevron-down</v-icon>
              </v-card>
            </v-hover>
          </v-card>
        </v-col>
        <v-col class="pa-0 mt-4 text-right" cols="12">
          <span class="caption mb-3"
            >(Los precios pueden variar según el pesos)</span
          >
          <h3 class="d-flex mt-4 title">
            <span class="ml-auto">Total: {{ calculoTotal.toFixed(2) }} €</span>
          </h3>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      mouseDownToScroll: false,
      cargandoRecogidaActivada: false,
      cargandoDomicilioActivado: false,
      cargandoTiempoDomicilio: false,
      cargandoTiempoRecogida: false,
      tiempoBloqueo: 2000,
    };
  },
  computed: {
    tiempoDomicilio: {
      get() {
        return this.$store.state.tiempoDomicilio;
      },
      set(v) {
        this.$store.commit("setTiempoDomicilio", v);
      },
    },
    tiempoRecogida: {
      get() {
        return this.$store.state.tiempoRecogida;
      },
      set(v) {
        this.$store.commit("setTiempoRecogida", v);
      },
    },
    carritoInterno: {
      get() {
        return this.$store.state.internos.pedidoEnCurso.carritoInterno;
      },
      set(v) {
        this.$store.commit("app/setCarritoInterno", v);
      },
    },
    calculoTotal: function () {
      let precio = 0;
      this.carritoInterno.forEach(function (e) {
        precio += e.cantidad * e.precio;
      });
      return precio;
    },
    ...mapState({
      isLoaded: (state) => state.loaded,
    }),
  },
  methods: {
    ...mapActions({
      setTiempoDomicilio: "setTiempoDomicilio",
      setTiempoRecogida: "setTiempoRecogida",
      deleteElementPedido: "internos/deleteElementPedido",
    }),
    deleteThis(id) {
      this.deleteElementPedido(id);
    },
    sumarRestar(operacion, tipo) {
      let minutos = 5;
      switch (tipo) {
        case "reparto":
          operacion == "suma"
            ? (this.tiempoDomicilio += minutos)
            : this.tiempoDomicilio - minutos >= 0
            ? (this.tiempoDomicilio -= minutos)
            : null;

          break;
        default:
          operacion == "suma"
            ? (this.tiempoRecogida += minutos)
            : this.tiempoRecogida - minutos >= 0
            ? (this.tiempoRecogida -= minutos)
            : null;

          break;
      }
    },
    scrollTo(move) {
      let _vue = this;
      this.mouseDownToScroll = true;

      let interval = setInterval(function () {
        let containerCarrito = document.querySelector("#containerCarrito");
        let pos = containerCarrito.scrollTop;
        let to = move == "up" ? pos - 5 : pos + 5;

        containerCarrito.scrollTop = to;

        if (!_vue.mouseDownToScroll) {
          clearInterval(interval);
        }
      }, 10);
    },
  },
  mounted() {},
  watch: {
    tiempoDomicilio: function (newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoTiempoDomicilio) {
        this.cargandoTiempoDomicilio = true;

        setTimeout(function () {
          _vue.setTiempoDomicilio(_vue.tiempoDomicilio);
          _vue.cargandoTiempoDomicilio = false;
        }, this.tiempoBloqueo);
      }
    },
    tiempoRecogida: function (newVal, oldVal) {
      let _vue = this;

      if (!this.cargandoTiempoRecogida) {
        this.cargandoTiempoRecogida = true;

        setTimeout(function () {
          _vue.setTiempoRecogida(_vue.tiempoRecogida);
          _vue.cargandoTiempoDomicilio = false;
        }, this.tiempoBloqueo);
      }
    },
  },
};
</script>
	
<style scoped>
.border {
  border: 1px solid #000;
}
</style>