// https://vuex.vuejs.org/en/state.html
export default {
	restauranteObj: null,
	negocioObj: null,
	loaded: false,
	tiempoDomicilio: 10,
	tiempoRecogida: 10,
	recogidaActivada: false,
	domicilioActivado: false,
	horaInicio: 6,
	impuestos: [],
	modoDesarrollo: 'desarrollo' // desarrollo o produccion
}
