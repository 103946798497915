export default {
  setDialog(state, v) {
    state.dialog = v;
  },
  setNotificacionLanzar(state, not) {
    state.dialog = true;
    state.notificacionLanzada = { ...not };
  },
  setNotificacionesListado(state, nots) {
    state.notificacionesListado = nots;
  }
}