<template>
  <div>
    <v-row class>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="pedidos"
          item-key="id"
          :custom-filter="customFilter"
          sort-by="numPedidoRestDia"
          sort-desc
          :expanded.sync="expanded"
          show-expand
          :search="search"
          class="elevation-1"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr :class="item.colorFila">
              <td>
                {{
                  item.numPedidoRestDiaDirectorio
                    ? "Multi-" + item.numPedidoRestDiaDirectorio
                    : item.numPedidoRestDia
                }}
              </td>
              <td>{{ item.fechaMostrar }}</td>

              <!-- <td>
                <v-chip color="info">{{ item.fechaEntrega }}</v-chip>
              </td> -->
              <!--<td>{{item.name}}</td>-->
              <td>{{ capitalizeFirstLetter(item.estado) }}</td>
              <td>{{ capitalizeFirstLetter(item.modo) }}</td>
              <td>{{ item.precioTotal.toFixed(2) }}</td>
              <td>
                <v-row justify="end">
                  <v-tooltip top v-if="item.estadoCocina != 'parado'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        :loading="loadingImprimir"
                        large
                        v-on="on"
                        @click="pararEnCocina(item)"
                      >
                        <v-icon color="yellow darken-4" big>mdi-pause</v-icon>
                      </v-btn>
                    </template>
                    <span>Parar en cocina</span>
                  </v-tooltip>
                  <v-tooltip top v-if="item.estadoCocina == 'parado'">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        :loading="loadingImprimir"
                        large
                        v-on="on"
                        @click="reanudarEnCocina(item)"
                      >
                        <v-icon color="green darken-4" big>mdi-play</v-icon>
                      </v-btn>
                    </template>
                    <span>Reanundar en cocina</span>
                  </v-tooltip>
                  <v-badge
                    :content="item.contadorMensaje"
                    :value="item.contadorMensaje > 0"
                    left
                    color="secondary"
                    overlap
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          :loading="loadingImprimir"
                          large
                          v-on="on"
                          @click="goMensajes(item)"
                        >
                          <v-icon color="purple darken-1" big
                            >mdi-message</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Tiene {{ item.contadorMensaje }} mensajes</span>
                    </v-tooltip>
                  </v-badge>
                  <v-tooltip top v-if="!!item.estadoReparto">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="openReparto(item)"
                        class="px-2 ml-1"
                        icon
                        large
                        v-on="on"
                      >
                        <v-icon :color="item.repartoColor" big>mdi-bike</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver reparto</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="yellow"
                        icon
                        :loading="loadingImprimir"
                        large
                        v-on="on"
                        @click="imprimir(item)"
                      >
                        <v-icon class="green--text" big>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>Imprimir</span>
                  </v-tooltip>
                  <v-tooltip top v-if="!item.eliminado">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        large
                        v-on="on"
                        @click="goEditar(item)"
                      >
                        <v-icon class="blue--text" big>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip
                    top
                    v-if="!item.solicitarEliminado && !item.eliminado"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        large
                        v-on="on"
                        :loading="item.loadingEliminar"
                        @click="goSolicitarEliminado(item)"
                      >
                        <v-icon class="red--text" big>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Solicitar Eliminación</span>
                  </v-tooltip>
                </v-row>
              </td>
              <td v-if="item.multiple">
                <v-icon @click="expand(true)" v-if="!isExpanded"
                  >mdi-arrow-down</v-icon
                >
                <v-icon @click="expand(false)" v-if="isExpanded"
                  >mdi-arrow-up</v-icon
                >
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row>
                <v-col cols="12">
                  <v-list dense>
                    <v-subheader>ASOCIADOS</v-subheader>
                    <v-list-item
                      dense
                      v-for="(p, i) in item.multipleHijosObj"
                      :key="i"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ p.id }} - Ticket día
                          {{ p.numPedidoRestDia }}</v-list-item-title
                        >

                        <v-list-item-subtitle>
                          {{ p.precioTotal }}</v-list-item-subtitle
                        ><v-divider />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row dense>
                          <v-spacer></v-spacer>
                          <!-- <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                disabled
                                class="px-2 ml-1"
                                color="purple"
                                min-width="0"
                                small
                                v-on="on"
                              >
                                <v-icon class="white--text" small
                                  >mdi-printer</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Imprimir {{ item.id }}</span>
                          </v-tooltip> -->
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
        <v-dialog v-model="eliminar.dialogo" max-width="800px">
          <v-card>
            <v-card-title class="headline grey lighten-2 pb-4" primary-title>
              Solicitud de eliminación del pedido
              {{ eliminar.pedido ? eliminar.pedido.numPedidoRest : "" }}
              | número
              {{ eliminar.pedido ? eliminar.pedido.numPedidoRestDia : "" }} del
              día
            </v-card-title>
            <v-card-text class="subtitle-1 mt-3">
              Va a solicitar la eliminación del pedido. Hasta que no se confirme
              aparecerá en los cierres Z.</v-card-text
            >

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="confirmarEliminar"
                :loading="eliminar.loading"
                >SOLICITAR ELIMINACIÓN</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="pedidoMensajeDialog" max-width="800px" width="80%">
          <v-card class="mx-auto">
            <v-toolbar color="primary" dark>
              <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->

              <v-toolbar-title
                >MENSAJES DEL PEDIDO {{ pedidoMensaje.numPedidoRest }} | PEDIDO
                DÍA {{ pedidoMensaje.numPedidoRestDia }}</v-toolbar-title
              >

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </v-toolbar>

            <v-row dense>
              <v-col>
                <div v-for="(m, i) in mensajesComputados" :key="i">
                  <v-card-title>{{ m.fechaMostrar }}</v-card-title>
                  <v-card-text>{{ m.adicional.mensaje }}</v-card-text>
                </div>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    class="primary"
                    @click="pedidoMensajeDialog = false"
                    x-large
                    >Cerrar</v-btn
                  >
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { capitalizeFirstLetter } from "@/utils/helper.utils.js";
import moment from "moment-timezone";

export default {
  components: {},
  data() {
    return {
      expanded: [],
      loadingImprimir: false,
      filters: {
        fechaFilter: "",
        nombreFilter: "",
        estadoFilter: "",
        precioFilter: "",
      },
      eliminar: {
        dialogo: false,
        loading: false,
        pedido: null,
        motivos: this.$store.state.pedidos.eliminarMotivos,
        motivo: "pruebas",
      },
      pedidoMensajeDialog: false,
      pedidoMensaje: { id: null },
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "numPedidoRestDia",
          width: "10%",
        },
        {
          text: "Hora pedido",
          value: "fechaMostrar",
          filter: "",
          width: "15%",
        },
        // {
        //   text: "Hora entrega",
        //   value: "fechaEntrega",
        //   filter: "",
        //   width: "15%",
        // },
        //{ text: "Nombre", value: "name", filter: "", width: "30%" },
        { text: "Estado", value: "estado", filter: "", width: "15%" },
        { text: "Modo", value: "modo", filter: "", width: "10%" },
        { text: "Precio", value: "precioTotal", filter: "", width: "10%" },
        {
          text: "Acciones",
          value: "acciones",
          filter: "",
          width: "25%",
          align: "end",
        },
        { text: "", value: "data-table-expand", width: "10%" },
      ],
    };
  },
  computed: {
    mensajesComputados() {
      return this.pedidoMensaje.historial
        ? this.pedidoMensaje.historial
            .filter((p) => p.mensaje.includes("Mensaje desde la web"))
            .map((p) => {
              return {
                ...p,
                fechaMostrar: moment.unix(p.fecha).format("DD-MM-YYYY HH:mm"),
              };
            })
        : [];
    },
    ...mapState({
      isLoaded: (state) => state.loaded,
      pedidosRaw: (state) => state.pedidos.pedidosArray,
      pedidosDirectorioMatrizRaw: (state) =>
        state.pedidos.pedidosDirectorioMatriz,
    }),
    ...mapGetters({
      //pedidos: "clientes/clientesPedidosListado",
    }),
    pedidos() {
      var pedidosRecorrer;
      if (this.$store.state.directorio.directorioMatriz) {
        pedidosRecorrer = [
          ...this.pedidosRaw,
          ...this.pedidosDirectorioMatrizRaw.filter((p) => {
            return (
              p.negocio + "-" + p.restaurante !== p.restauranteDirectorioMatriz
            );
          }), //eliminaos los que el restaurante matriz es sobre dónde se ha hecho el pedido, porque vienen en pedidosRaw
        ].filter((p) => !!p);
        // pedidosRecorrer = this.pedidosRaw;
      } else {
        pedidosRecorrer = this.pedidosRaw;
      }
      let devolver = pedidosRecorrer.map((p) => {
        let colorFila = "";
        let zonaHoraria = p.copyRest.confGeneral.zonaHoraria
          ? p.copyRest.confGeneral.zonaHoraria
          : "Europe/Madrid";

        if (p.eliminado) {
          colorFila = "red lighten-2";
        } else if (p.solicitarEliminado) {
          colorFila = "yellow lighten-1";
        }
        let contadorMensaje = p.historial
          ? p.historial.reduce(
              (n, h) => n + h.mensaje.includes("Mensaje desde la web"),
              0
            )
          : 0;

        let auxReparto = {};
        switch (
          p.estadoReparto //no-gestionado, creado, asignado, recogido, entregado, problema
        ) {
          case "no-gestionado":
            auxReparto.repartoColor = "orange";
            auxReparto.repartoTexto = "Sin gestionarse";
            break;
          case "creado":
            auxReparto.repartoColor = "yellow";
            auxReparto.repartoTexto = "Creado la petición de reparto";
            break;
          case "asignado":
            auxReparto.repartoColor = "pink";
            auxReparto.repartoTexto = "Asignado a repartidor";
            break;
          case "en-camino":
            auxReparto.repartoColor = "blue";
            auxReparto.repartoTexto = "En reparto";
            break;
          case "entregado":
            auxReparto.repartoColor = "green";
            auxReparto.repartoTexto = "Entregado";
            break;
          case "problema":
            auxReparto.repartoColor = "red";
            auxReparto.repartoTexto = "Problema en el reparto";
            break;
          default:
            auxReparto.repartoColor = "red";
            auxReparto.repartoTexto = "Sin más info";
            break;
        }

        return {
          ...p,
          fechaMostrar: moment(p.fechaServidor.toDate())
            .tz(zonaHoraria)
            .format("HH:mm"),
          fechaEntrega: moment
            .unix(p.programadoFecha)
            .tz(zonaHoraria)
            .format("HH:mm"),
          colorFila: colorFila,
          contadorMensaje: contadorMensaje,
          ...auxReparto,
        };
      });

      //acomplamos pedidos múltiples
      devolver = devolver.map((multi) => {
        if (multi.multipleHijos && multi.multipleHijos.length > 0) {
          return {
            ...multi,
            multipleHijosObj: multi.multipleHijos.map((hijo) => {
              let found = devolver.find((p) => p.id == hijo);
              return found ? found : {};
            }),
          };
        } else {
          return multi;
        }
      });
      if (this.$store.state.directorio.directorioMatriz) {
        // es el restaurante matriz del directorio y no tiene que mostrar hijos
        devolver = devolver.filter((p) => !p.multiplePadre);
      }
      return devolver;
    },
    search() {
      let res = "";
      for (let item in this.filters) {
        res += item;
      }
      return res;
    },
  },
  methods: {
    openReparto(item) {
      window.open(item.vromoEnlace, "_blank");
    },
    goMensajes(item) {
      this.pedidoMensaje = item;
      this.pedidoMensajeDialog = true;
    },
    goSolicitarEliminado(item) {
      this.eliminar.dialogo = true;
      this.eliminar.pedido = item;
    },
    goEditar(item) {
      this.$router.push("/pedido/" + item.id);
    },
    capitalizeFirstLetter(s) {
      return capitalizeFirstLetter(s);
    },
    customFilter(value, search, item) {
      let fecha = item.fecha != undefined ? item.fecha : "";
      let nombre = item.name != undefined ? item.name : "";
      let estado = item.estado != undefined ? item.estado : "";
      let precio = item.precioTotal != undefined ? item.precioTotal : "";

      if (typeof fecha != "String" && fecha != undefined)
        fecha = fecha.toString().toLowerCase();
      if (typeof nombre != "String" && nombre != undefined)
        nombre = nombre.toString().toLowerCase();
      if (typeof estado != "String" && estado != undefined)
        estado = estado.toString().toLowerCase();
      if (typeof precio != "String" && precio != undefined)
        precio = precio.toString().toLowerCase();

      return (
        fecha.includes(this.filters.fechaFilter) &&
        nombre.includes(this.filters.nombreFilter) &&
        estado.includes(this.filters.estadoFilter) &&
        precio.includes(this.filters.precioFilter)
      );
    },
    imprimir(item) {
      const _vm = this;
      this.loadingImprimir = true;
      return this.$store
        .dispatch("pedidos/imprimirPedido", item)
        .then((r) => {
          const d = r.data;
          if (d.ok) {
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Ticket impreso",
            });
          } else {
            this.$store.commit("snackbar/setMensajeDanger", {
              msj: "Error al imprimir el ticket: " + d.error,
            });
          }
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al imprimir el ticket: " + e,
          });
        })
        .finally(() => (_vm.loadingImprimir = false));
    },
    pararEnCocina(item) {
      const _vm = this;
      this.loadingImprimir = true;
      return this.$store
        .dispatch("pedidos/pararEnCocina", item)
        .then((r) => {
          const d = r.data;
          if (d.ok) {
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Se ha enviado la notificación de parar en cocina",
            });
          } else {
            this.$store.commit("snackbar/setMensajeDanger", {
              msj: "Error al parar en cocina: " + d.error,
            });
          }
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al parar en cocina: " + e,
          });
        })
        .finally(() => (_vm.loadingImprimir = false));
    },
    reanudarEnCocina(item) {
      const _vm = this;
      this.loadingImprimir = true;
      return this.$store
        .dispatch("pedidos/reanudarEnCocina", item)
        .then((r) => {
          const d = r.data;
          if (d.ok) {
            this.$store.commit("snackbar/setMensajeSuccess", {
              msj: "Se ha enviado la notificación de reanudar en cocina",
            });
          } else {
            this.$store.commit("snackbar/setMensajeDanger", {
              msj: "Error al reanudar en cocina: " + d.error,
            });
          }
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al reanudar en cocina: " + e,
          });
        })
        .finally(() => (_vm.loadingImprimir = false));
    },
    goEdit(item) {
      this.$router.push("/pedido/" + item.id);
    },
    confirmarEliminar() {
      let _vm = this;
      this.eliminar.pedido.loadingEliminar = true;

      this.$store
        .dispatch("pedidos/solicitarEliminacion", this.eliminar.pedido)
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido solicitado eliminar",
          });
          this.eliminar.dialogo = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al solicitar eliminación: " + e,
          });
        })
        .finally(() => {
          item.loadingEliminar = true;
        });
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scope>
</style>