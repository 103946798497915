export default {
  numPedidos(state, getters, rootState) {
    var pedidosRecorrer
    if (rootState.directorio.directorioMatriz) {
      // pedidosRecorrer = [
      //   ...this.pedidosRaw,
      //   ...this.pedidosDirectorioMatrizRaw.filter((p) => {
      //     return (
      //       p.negocio + "-" + p.restaurante !== p.restauranteDirectorioMatriz
      //     );
      //   }), //eliminaos los que el restaurante matriz es sobre dónde se ha hecho el pedido, porque vienen en pedidosRaw
      // ].filter((p) => !!p);
      pedidosRecorrer = [
        ...state.pedidosArray,
        ...state.pedidosDirectorioMatriz.filter((p) => {
          return p.negocio + "-" + p.restaurante != p.restauranteDirectorioMatriz
            ;
        }), //eliminaos los que el restaurante matriz es sobre dónde se ha hecho el pedido, porque vienen en pedidosRaw
      ].filter((p) => !!p && p.negocio + "-" + p.restaurante != p.restauranteDirectorioMatriz)
      // pedidosRecorrer = this.pedidosRaw;
    } else {
      pedidosRecorrer = state.pedidosArray;
    }
    return pedidosRecorrer.filter(p => !p.eliminado).length
  },
}