import moment from 'moment'
export default {
  getProductosListado(state, getters, rootState) {
    const cats = state.categorias;
    const imps = rootState.impuestos.impuestosArray;
    var res = state.productos.map(e => {
      let cFound = cats.find(c => c.id == e.categoria);
      let iFound = imps.find(c => c.id == e.impuesto);

      e.categoriaNombre = (cFound) ? cFound.nombre : 'Categoría sin definir';
      e.impuestoNombre = (iFound) ? iFound.nombre : 'Impuesto sin definir';
      return e;
    })
    return res;
  },
  getPedidosListado(state, getters, rootState) {
    const p = state.pedidos;
    const estados = state.estados;
    var res = [];
    p.forEach(e => {
      res.push({
        ...e,
        estado: estados.find(es => es.value == e.estado) ? estados.find(es => es.value == e.estado) : 'Sin estado',
        fechaEspera: (e.fechaEspera) ?
          moment(e.fechaEspera.toDate()).format('DD-MM-YY HH:mm') : '-',
        fechaEnviado: (e.fechaEnviado) ?
          moment(e.fechaEnviado.toDate()).format('DD-MM-YY HH:mm') : '-'
      })
    });
    return res;
  },
  enviarButtonDisabled(state) {
    return !state.pedidoGuardado ||
      state.pedidoEnCurso.carritoInterno.length == 0
      || state.pedidoEnviado
  },
  lanzarModalComprobarPedido(state) {
    return state.pedidoEnCursoComprobar.id
      && state.pedidoEnCurso.id != state.pedidoEnCursoComprobar.id && state.pedidoEnCursoComprobar.estado == 'realizando'
  }
}