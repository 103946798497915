<template>
  <v-container>
    <v-row id="pedir">
      <!--<v-subheader>PEDIDO ID: {{pedidoEnCursoComprobar.numPedidoInternos}}</v-subheader>-->
      <v-col class="px-0" cols="10">
        <v-tabs v-model="eleccion" center-active color="dark" grow>
          <v-tab
            active-class="activeTab"
            v-for="(item, i) in categorias"
            :key="i"
            >{{ item.nombre }}</v-tab
          >
        </v-tabs>
        <v-data-table
          id="containerCategorias"
          class="elevation-1 customTable"
          disable-sort
          :headers="headers"
          :items="productosComputados()"
          :custom-filter="customFilter"
          :search="search"
          :disable-pagination="true"
          hide-default-footer
        >
          <template v-slot:top>
            <table>
              <tr>
                <th class="px-4"></th>
                <th class="pa-2">
                  <v-text-field
                    class="customInput"
                    v-model="filters.nombreFilter"
                    placeholder="Buscar..."
                    hide-details
                    dense
                    outlined
                    single-line
                  ></v-text-field>
                </th>
                <th class="pa-2"></th>
                <th class="pa-2"></th>
              </tr>
            </table>
          </template>
          <template v-slot:item="{ item }">
            <tr :class="colorRow(item)">
              <td>{{ item.cantidad }}</td>
              <td>{{ item.nombre }}</td>
              <td>{{ item.precio }}</td>
              <td>
                <v-row class="d-flex flex-row-reverse">
                  <v-btn
                    v-if="!esVer"
                    fab
                    dark
                    class="ma-2"
                    small
                    color="black"
                    min-width="0"
                    @click="mas(item, 0)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!esVer"
                    fab
                    dark
                    class="ma-2"
                    small
                    color="black"
                    min-width="0"
                    @click="menos(item)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-row>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <v-col class="d-none d-md-flex" cols="2">
        <v-card flat color="transparent" class width="100%">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="d-flex align-center justify-center mb-2"
              width="100%"
              height="125px"
              :color="hover ? 'grey lighten-4' : 'white'"
              @mouseup="mouseDownToScroll = false"
              @mousedown="scrollTo('up')"
            >
              <v-icon size="40">mdi-chevron-up</v-icon>
            </v-card>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="d-flex align-center justify-center mt-2"
              width="100%"
              height="125px"
              :color="hover ? 'grey lighten-4' : 'white'"
              @mouseup="mouseDownToScroll = false"
              @mousedown="scrollTo('down')"
            >
              <v-icon size="40">mdi-chevron-down</v-icon>
            </v-card>
          </v-hover>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogComprobarPedido" width="650" persistent>
      <v-card>
        <v-card-title class="headline">Tiene un pedido guardado</v-card-title>
        <v-card-text
          >¿Quiere continuar con el pedido que inicio el
          {{ fechaInicioComprobar }} con id
          {{ pedidoEnCursoComprobar.numPedidoInternos }} ?</v-card-text
        >
        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="eliminarPedidoComprobar"
            >No (se eliminará el pedido guardado)</v-btn
          >
          <v-spacer />
          <v-btn color="green darken-1" text @click="continuarPedidoComprobar"
            >Si</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";

export default {
  data() {
    return {
      //guardado: false,
      //loadingSave: false,
      mouseDownToScroll: false,
      valorFiltrar: null,
      filtroCarrito: [],
      eleccion: 0,
      accion: null,
      idpedido: null,
      productosMostrar: [],
      filters: {
        nombreFilter: "",
      },
      headers: [
        {
          text: "#",
          value: "cantidad",
          filter: "",
        },
        { text: "Nombre", value: "nombre", filter: "" },
        { text: "Precio", value: "precio", filter: "" },
        {
          sortable: false,
          text: "Acciones",
          value: "acciones",
          align: "right",
        },
      ],
    };
  },
  metaInfo: {
    title: "Default Title",
  },
  watch: {
    eleccion(val, oldVal) {
      this.valorFiltrar = this.categorias[val];
      this.productosMostrar = this.filtrar();
      this.limpiarFiltros();

      if (val != oldVal) this.scrollCategoriasTop();
    },
    productos(val) {
      this.productosMostrar = this.filtrar();
    },
    categorias(val) {
      this.valorFiltrar = this.categorias[this.eleccion];
      this.productosMostrar = this.filtrar();
    },
  },
  computed: {
    titulo() {
      return this.esVer
        ? "Ver pedido interno " + this.id
        : "Añadir pedido interno";
    },
    dialogComprobarPedido: {
      get() {
        return this.dialogComprobar && this.idpedido == null;
      },
      set(v) {}, //no se hace nada porque se controla desde el store
    },
    dialogComprobar() {
      return this.$store.getters["internos/lanzarModalComprobarPedido"];
    },
    guardado: {
      get() {
        return this.$store.state.internos.pedidoGuardado;
      },
      set(val) {
        this.$store.commit("internos/setPedidoGuardado", val);
      },
    },
    loadingSave: {
      get() {
        return this.$store.state.internos.pedidoGuardadoLoading;
      },
      set(val) {
        this.$store.commit("internos/setPedidoGuardadoLoading", val);
      },
    },
    fechaInicioComprobar() {
      return this.pedidoEnCursoComprobar.fechaInicio
        ? moment(this.pedidoEnCursoComprobar.fechaInicio.toDate()).format(
            "DD-MM-YYYY HH:mm"
          )
        : "";
    },
    ...mapState({
      categorias: (state) => state.internos.categorias,
      productos: (state) => state.internos.productos,
      pedidoEnCursoComprobar: (state) => state.internos.pedidoEnCursoComprobar,
    }),
    ...mapGetters({
      pedidos: "clientes/clientesPedidosListado",
    }),
    carritoInterno: {
      get() {
        return this.$store.state.internos.pedidoEnCurso.carritoInterno;
      },
      set(v) {
        this.$store.commit("app/setCarritoInterno", v);
      },
    },
    pedidoEnCurso() {
      return this.$store.state.internos.pedidoEnCurso;
    },
    search() {
      let res = "";
      for (let item in this.filters) {
        res += item;
      }
      return res;
    },
    esVer() {
      return this.accion == "ver";
    },
  },
  methods: {
    colorRow(item) {
      return item.cantidad > 0 ? "green lighten-5" : "";
    },
    productosComputados() {
      return this.valorFiltrar
        ? this.productos
            .filter((i) => i.categoria == this.valorFiltrar.id)
            .sort(function (a, b) {
              if (a.nombre < b.nombre) {
                return -1;
              }
              if (a.nombre > b.nombre) {
                return 1;
              }
              return 0;
            })
            .map((e) => {
              var foundItem = this.carritoInterno.find((p) => {
                return e.id == p.id;
              });

              if (foundItem) {
                return {
                  ...e,
                  cantidad: foundItem.cantidad,
                };
              } else {
                return {
                  ...e,
                  cantidad: 0,
                };
              }
            })
        : [];
    },
    filtrar() {
      var res = [];
      return this.valorFiltrar
        ? this.productos
            .filter((i) => i.categoria == this.valorFiltrar.id)
            .map((e) => {
              e.cantidad = 0;
              return e;
            })
        : [];
    },
    mas(item, i) {
      var found = this.carritoInterno.findIndex((prod) => {
        return prod.id == item.id;
      });
      if (found > -1) {
        this.carritoInterno[found].cantidad += 1;
      } else {
        this.carritoInterno.push({
          ...item,
          cantidad: 1,
        });
      }
      this.modificacion();
    },
    menos(item) {
      var found = this.carritoInterno.findIndex((prod) => {
        return prod.id == item.id;
      });
      if (found > -1) {
        if (this.carritoInterno[found].cantidad == 1) {
          // _.pullAt(this.carritoInterno, found);
          this.carritoInterno.splice(found, 1);
        } else {
          this.carritoInterno[found].cantidad -= 1;
        }
      } else {
      }
      // this.modificacion();
    },
    modificacion() {
      this.guardado = false;
      this.$forceUpdate();
    },
    guardar() {
      var _vm = this;
      _vm.loadingSave = true;
      this.$store
        .dispatch("internos/savePedido")
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido guardado",
          });
          _vm.guardado = true;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al guardar el pedido: " + e,
          });
        })
        .finally(() => {
          _vm.loadingSave = false;
        });
    },
    nombresEnCarrito(nombre) {
      let cant = 0;
      if (this.carritoInterno != undefined) {
        this.carritoInterno.forEach((e) => {
          e.nombre == nombre ? (cant += e.cantidad) : null;
        });
      }
      return cant;
    },
    continuarPedidoComprobar() {
      this.$store.commit("internos/continuarPedidoComprobar");
    },
    eliminarPedidoComprobar() {
      this.$store.dispatch("internos/eliminarPedidosRealizando");
    },
    customFilter(value, search, item) {
      let nombre = item.nombre;

      if (typeof nombre != "String") nombre = nombre.toString().toLowerCase();

      return nombre.includes(
        this.filters.nombreFilter.toString().toLowerCase()
      );
    },
    limpiarFiltros() {
      for (let e in this.filters) {
        this.filters[e] = "";
      }
    },
    scrollTo(move) {
      let _vue = this;
      this.mouseDownToScroll = true;
      let duration = 50;

      let interval = setInterval(function () {
        let containerCategorias = document.querySelector(
          ".customTable div.v-data-table__wrapper"
        );
        let pos = containerCategorias.scrollTop;
        let to = move == "up" ? pos - 5 : pos + 5;

        containerCategorias.scrollTop = to;

        if (!_vue.mouseDownToScroll) {
          clearInterval(interval);
        }
      }, 10);
    },
    scrollCategoriasTop() {
      let containerCategorias = document.querySelector(
        ".customTable div.v-data-table__wrapper"
      );
      containerCategorias.scrollTop = 0;
    },
  },
  destroyed() {
    this.$store.commit("internos/resetPedidoEnCurso");
  },
  mounted() {
    this.valorFiltrar = this.categorias[this.eleccion];
    this.productosMostrar = this.filtrar();
    this.idpedido = this.$route.params.idpedido;
    this.accion = this.$route.params.accion;
    if (this.accion == "add") {
      if (this.idpedido) {
        //continuar pedido
        this.$store.dispatch("internos/comprobarPedido").then((r) => {
          this.$store.commit("internos/continuarPedidoComprobar");
        });
      } else {
        this.$store.dispatch("internos/comprobarPedido").then((r) => {});
      }
    } else {
      this.$store
        .dispatch("internos/getPedidoInterno", this.idpedido)
        .then((r) => {
          console.log(r);
          this.$store.commit("internos/setPedidoEnCurso", r);
        })
        .catch((e) => {
          console.log(e);
          this.$router.push("/pedidos-internos");
        });
    }
  },
};
</script>
<style>
.activeTab {
  background-color: #212121;
  color: white !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.customInput input {
  text-align: left !important;
  font-weight: 400 !important;
}
.customInput input::placeholder {
  font-size: 12px !important;
}
.customTable {
  border-top: 1px solid #212121;
  border-left: 1px solid #212121;
  border-radius: 0px !important;
}

.customTable div.v-data-table__wrapper {
  height: 500px;
  max-height: 320px;
  overflow-y: auto;
}

.border {
  border: 1px solid #000;
}
</style>