export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export const mostrarIdioma = (s) => {
  //hay que gestionar idiomas
  let lang = 'es'
  let mostrar = s.find(e => e.lang == lang)
  return mostrar.text;
}