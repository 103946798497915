export default {
    PRODUCTOS_RESTAURANTE(state, getters, rootState) {
        console.log(state.productosArray);
        console.log(rootState.restauranteObj);
        let asoc = rootState.restauranteObj.productosAsociados;
        return state.productosArray.map(p => {
            let found = asoc.find(pAsoc => pAsoc.id == p.id)
            return (found) ?
                {
                    ...p,
                    ...found,
                    asociado: true
                } :
                {
                    ...p,
                    asociado: false
                }

        })
    }
}