<template>
  <v-dialog v-model="dialog" max-width="80%" :persistent="esNueva">
    <v-card class="mx-auto">
      <v-toolbar color="primary" dark>
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->

        <v-toolbar-title>{{!esNueva ? "VER NOTIFICACIÓN" : "NUEVA NOTIFICACIÓN INTERNA"}}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container>
        <v-row dense>
          <v-col>
            <v-card-title>{{not.titulo}}</v-card-title>
            <v-card-text v-html="not.contenido"></v-card-text>
            <v-card-actions v-if="esNueva">
              <v-spacer />
              <v-btn
                @click="responderNotificacion(boton.msj)"
                :loading="loadingButton"
                v-for="boton in botones"
                :key="boton.msj"
                :class="boton.color"
                x-large
              >{{boton.msj}}</v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              <v-spacer />
              <v-btn class="primary" @click="dialog=false" x-large>Cerrar</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loadingButton: false,
    };
  },
  computed: {
    ...mapState({
      not: (state) => state.notificaciones.notificacionLanzada,
      botonesDefault: (state) => state.notificaciones.botonesDefault,
    }),
    esNueva() {
      return !this.not.visualizar;
    },
    dialog: {
      get() {
        return this.$store.state.notificaciones.dialog;
      },
      set(v) {
        this.$store.commit("notificaciones/setDialog", v);
      },
    },
    botones() {
      return this.not.botones ? this.not.botones : this.botonesDefault;
    },
  },
  methods: {
    responderNotificacion(respuesta) {
      var _vm = this;
      _vm.loadingButton = true;
      this.$store
        .dispatch("notificaciones/respuestaNotificacion", {
          respuesta: respuesta,
        })
        .then((r) => {
          if (!r.ok) return Promise.reject(r.error);
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Notificación contestada",
          });
          this.dialog = false;
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al guardar la respuesta: " + e,
          });
        })
        .finally(() => {
          _vm.loadingButton = false;
        });
    },
  },
};
</script>

<style>
</style>