export default {
  setProductosArray(state, p) {
    state.productosArray = p;
  },
  setCategorias(state, c) {
    state.categorias = c;
  },
  setCategoriaPidiendo(state, c) {
    state.categoriaPidiendo = c;
  },
  setPidiendoDialog(state, b) {
    state.pidiendoDialog = b;
  },
  setProductosPidiendo(state, p) { //array
    state.productosPidieendo = p;
  },
  setProductoPidiendo(state, p) {
    state.productoPidiendo = p;
  },
}