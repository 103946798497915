import { db, functions } from '@/firebase'
import { parDiasComparar } from '@/utils/dias.utils.js'
import { restSinNegocio } from '@/utils/restaurantes.utils.js'
import { actualizarCarrito } from '@/utils/pedido.utils.js'
import moment from 'moment'
import firebase from 'firebase'
export default {
  getPedidos({ commit, rootState }) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();

    return db.collection('pedidos')
      .where('fechaServidor', '>=', dias[0])
      .where('fechaServidor', '<=', dias[1])
      .where('restaurante', '==', restSin)
      .where('negocio', '==', negocio)
      .where('estado', '==', 'finalizado')
      .onSnapshot((snapDoc) => {
        var res = []
        snapDoc.forEach(e => {
          res.push({
            ...e.data(),
            id: e.id
          });
        });
        commit('setPedidos', res);
        return
      })
  },
  getPedidosDirectorioMatriz({ commit, rootState, state }) {
    // const restSin = restSinNegocio(rootState.restauranteObj);
    // const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    return db.collection('pedidos')
      .where('fechaServidor', '>=', dias[0])
      .where('fechaServidor', '<=', dias[1])
      .where('restauranteDirectorioMatriz', '==', rootState.restauranteObj.id)
      // .where('negocioMatriz', '==', negocio)
      .where('estado', '==', 'finalizado')
      .onSnapshot((snapDoc) => {
        var res = []
        snapDoc.forEach(e => {
          res.push({
            ...e.data(),
            id: e.id
          });
        });
        commit('setPedidosDirectorioMatriz', res);
        return
      })
  },
  imprimirPedido({ }, pedido) {
    var imp = functions.httpsCallable('httpPedidosImprimirTpv');
    return imp(pedido);
  },
  pararEnCocina({ }, pedido) {
    var imp = functions.httpsCallable('httpPedidosPararEnCocinaTpv');
    return imp(pedido);
  },
  reanudarEnCocina({ }, pedido) {
    var imp = functions.httpsCallable('httpPedidosReanudarEnCocinaTpv');
    return imp(pedido);
  },

  addProducto({ rootState, commit, state }, payload) {
    let opciones;
    let opcionesNombre = [];
    let opcionesPrecio = [];
    let opcionesTipo = [];
    let impuestosGlobales = rootState.impuestos;

    if (state.seleccionOpciones.length > 0) {
      opciones = JSON.parse(JSON.stringify(state.seleccionOpciones)); //para convertir en un array normal
    } else {
      opciones = [];
    }


    var _vm = this;
    payload.opcionesName.forEach(function (e) {						//Carga los nombres de opciones
      opcionesNombre.push(_vm.$mostrarIdioma(e.title).trim())
      opcionesPrecio.push(parseFloat(_vm.$mostrarPrecio(e)))
    })

    var foundImp = impuestosGlobales.find((i) => i.id == state.activeProduct.impuesto);
    var impuestoValor = parseFloat(foundImp.impuesto);
    let carrito = JSON.parse(JSON.stringify(state.carrito.productos));

    let producto = {
      cantidad: payload.cantidad,
      id: state.activeProduct.id,
      //impuestoText: mostrarImpuestoTexto(state.activeProduct.impuesto, impuestosGlobales),
      impuesto: impuestoValor,
      nombre: this.$mostrarIdioma(state.activeProduct.nombre),
      opciones: opciones,
      opcionesName: opcionesNombre,
      opcionesPrecio: opcionesPrecio,
      categoria: state.activeProduct.categoria,
      precio: parseFloat(this.$mostrarPrecio(state.activeProduct)),
      multiple: aceptaMultiple,
    }
    if (aceptaMultiple) {
      producto.datosMultiple = {
        negocio: state.negocioPedido,
        rest: state.restaurantePedido,
        nombreMostrar: state.negocioObjPedido.nombre /*+ ' - ' + state.restauranteObjPedido.nombre*/
      }
    };

    /*********************************** Busca si ya existe ***********************************/
    let coincide = carrito.findIndex((e) => {
      return e.id === producto.id && (e.opciones).toString() === (producto.opciones).toString();
    })

    if (coincide >= 0) {
      carrito[coincide].cantidad += producto.cantidad
    } else {
      carrito.push(producto)
    }

    commit('ADD_PRODUCTO', carrito);
    commit('RESET_ACTIVE_PRODUCT');

  },
  cambiarPedido({ }, pedido) {
    let id = pedido.id;
    let pActualizado = actualizarCarrito(pedido);
    return db.collection('pedidos').doc(id).set({
      forma_pago: pedido.forma_pago,
      modo: pActualizado.modo,
      productos: pActualizado.productos,
      precioTotal: pActualizado.precioTotal,
      numeroProductos: pActualizado.numeroProductos,
      cambio: pActualizado.cambio ? pActualizado.cambio : 0
    }, { merge: true })
  },
  getPedido({ }, id) {
    return db.collection('pedidos').doc(id).get()
      .then((r) => {
        if (r.exists) {
          return {
            ...r.data(),
            id: r.id
          }
        } else {
          return Promise.reject("no encontrado")
        }
      })
  },
  solicitarEliminacion({ rootState }, item) {
    let usuario = (rootState.auth.user.email) ? rootState.auth.user.email : rootState.auth.user.uid;
    return db.collection('pedidos').doc(item.id).set({
      solicitarEliminado: true,
      historial: firebase.firestore.FieldValue.arrayUnion(
        {
          fecha: moment().unix(),
          usuario: usuario,
          mensaje: 'Solicitado eliminar desde TPV ' + usuario
        }
      )
    }, { merge: true })
  }
}