import { db } from '@/firebase'
import firebase from 'firebase'
import moment from 'moment'
export default {
  getNotificaciones({ rootState, commit }, payload) {
    const rest = rootState.auth.user.restauranteTpv
    return db.collection('restaurantes').doc(rest).collection('notificaciones')
      .where('estado', '==', 'lanzar')
      .where('activo', '==', true)
      .onSnapshot((nots) => {
        var notsArray = []
        nots.forEach((not) => {
          notsArray.push({
            ...not.data(),
            id: not.id
          })
        })
        notsArray.sort((a, b) => {
          return b.fechaCreacion.seconds - a.fechaCreacion.seconds
        })
        if (notsArray.length > 0) {
          commit('setNotificacionLanzar', notsArray[0]);
        } else { commit('setDialog', false) }
      })
  },
  respuestaNotificacion({ state }, payload) {
    var f = firebase.functions().httpsCallable('httpTpvRespuestaNotificacion');
    return f({
      ...payload,
      not: state.notificacionLanzada
    }).then((r) => { return r.data })
  },
  getNotificacionesListado({ commit, rootState }) {
    const rest = rootState.auth.user.restauranteTpv
    return db.collection('restaurantes').doc(rest).collection('notificaciones')
      .where('activo', '==', true)
      .get()
      .then((r) => {
        if (r.size > 0) {
          commit('setNotificacionesListado', r.docs.map(n => {
            return {
              ...n.data(),
              id: n.id
            }
          }).filter(n => {
            return n.fechaInicio.seconds < moment().unix()
          })
          )
        } else {
          commit('setNotificacionesListado', [])
        }

      })
  }
}