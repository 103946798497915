<template>
  <div class id="inspire">
    <v-app-bar height="78" app dark>
      <v-toolbar-title
        class="pa-0 ma-0 d-inline-flex"
        @click="$router.push('/dashboard')"
      >
        <v-img
          class="pa-0"
          src="/img/logoFunEats.svg"
          lazy-src="/img/logoFunEats.svg"
          contain
          width="150"
          height="30"
        ></v-img>
      </v-toolbar-title>
      <v-spacer />
      <span class="font-weight-medium text-uppercase d-none d-md-flex"
        >{{ negocioObj.nombre }} - {{ nombre }}</span
      >
      <template v-if="!!$store.state.directorio.directorioMatrizObj">
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-menu
          v-model="menuDirectorio"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn class outlined color="#00F4D9" v-on="on">
              DIRECTORIO
              {{ $store.state.directorio.directorioMatrizObj.nombre }}
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ $store.state.directorio.directorioMatrizObj.nombre }}
            </v-card-title>
            <v-card-text>Este restaurante maneja el directorio</v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-if="restObjBruto.mapeoNeo === true">
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-menu
          v-model="menuNeo"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on }">
            <v-btn class outlined color="#00F4D9" v-on="on">
              NEO
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item color="success">
                <v-list-item-content>
                  <v-list-item-title class="title"
                    >Sincronización Neo</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list two-line class="py-0">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-food</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1"
                    >Sincronización Productos</v-list-item-title
                  >
                  <v-list-item-subtitle>{{ fechaNeo }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    @click="actualizarNeoRestaurante"
                    inset
                    readonly
                    :loading="loadingNeo"
                    class="my-2"
                    color="primary"
                    ><v-icon>mdi-reload</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on }">
          <v-btn class outlined color="#00F4D9" v-on="on">
            Online
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item color="success">
              <v-list-item-content>
                <v-list-item-title class="title"
                  >Cerrar Online</v-list-item-title
                >
              </v-list-item-content>

              <v-list-item-action
                @click.stop="cambio(['domicilio', 'recoger'])"
              >
                <v-switch
                  inset
                  readonly
                  :loading="estado.loading"
                  v-model="estadoGeneral"
                  color="success"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list two-line class="py-0">
            <v-list-item>
              <v-list-item-icon>
                <v-chip v-if="domicilioAbierto" color="green">
                  <v-icon>mdi-check</v-icon>
                </v-chip>
                <v-chip v-else color="red">
                  <v-icon>mdi-close</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >DOMICILIO</v-list-item-title
                >
                <v-list-item-subtitle
                  v-for="(item, i) in horariosDomicilio"
                  class="text--primary"
                  :key="i"
                  >{{ item }}</v-list-item-subtitle
                >
                <h4
                  v-if="
                    restObj.modoHorarioDomicilio == 'forzado_cierre' &&
                    restObj.modoHorarioDomicilioTiempo > 0
                  "
                >
                  Cerrado hasta {{ restObj.modoHorarioDomicilioTiempoMostrar }}
                </h4>
              </v-list-item-content>
              <v-list-item-action @click.stop="cambio(['domicilio'])">
                <v-switch
                  inset
                  readonly
                  :loading="estado.loading"
                  class="my-2"
                  v-model="estadoDomicilio"
                  color="success"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-chip v-if="recogidaAbierta" color="green">
                  <v-icon>mdi-check</v-icon>
                </v-chip>
                <v-chip v-else color="red">
                  <v-icon>mdi-close</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >RECOGER</v-list-item-title
                >
                <v-list-item-subtitle
                  v-for="(item, i) in horariosRecoger"
                  class="text--primary"
                  :key="i"
                  >{{ item }}</v-list-item-subtitle
                >
                <h4 v-if="restObj.modoHorarioRecogerTiempo">
                  Cerrado hasta {{ restObj.modoHorarioRecogerTiempoMostrar }}
                </h4>
              </v-list-item-content>
              <v-list-item-action @click.stop="cambio(['recoger'])">
                <v-switch
                  inset
                  readonly
                  :loading="estado.loading"
                  class="my-2"
                  v-model="estadoRecoger"
                  color="success"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </v-list>
        </v-card>
      </v-menu>

      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn class color="red" dark @click="logout">
        <v-icon size="34" dark>mdi-power</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <v-container fluid class>
        <v-row no-gutters>
          <!-- Vista principal -->
          <v-col class cols="12" sm="6" md="8">
            <v-container>
              <v-list-item class="pa-0 ma-0">
                <v-list-item-content class="pa-0 ma-0">
                  <v-list-item-title class="headline font-weight-bold">{{
                    $router.currentRoute.name
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="pa-0 ma-0">
                  <v-btn @click="volver()" class dark x-large color>
                    <v-icon class="mr-4" left>mdi-arrow-left</v-icon>Volver
                  </v-btn>
                </v-list-item-icon>
              </v-list-item>

              <router-view />
            </v-container>
          </v-col>
          <v-col class="pa-3" cols="12" sm="6" md="4">
            <!-- Menu lateral -->
            <div class="p-sticky">
              <panelPedidosInternos
                v-if="
                  $router.currentRoute.path.includes('/pedidos-internos/add') ||
                  $router.currentRoute.path.includes('/pedidos-internos/ver')
                "
              />
              <panelLateral v-else />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <v-footer height="78" dark app class="pa-0">
      <footerComponent />
    </v-footer>
    <notificacion-dialog id="notificacines" />
    <v-dialog
      id="tiempoForzadoCierre"
      v-model="tiempoForzadoCierreDialog"
      max-width="750px"
    >
      <v-card>
        <v-card-title class="text-center">
          <v-row justify="center"
            >¿Cuanto tiempo quiere cerrar
            {{
              tiempoForzadoCierreModo
                ? tiempoForzadoCierreModo.join(" y ")
                : ""
            }}?</v-row
          >
        </v-card-title>
        <v-card-text>
          <v-row align="center" justify="center" class="white--text">
            <v-col cols="12" md="3">
              <v-btn
                x-large
                color="green"
                width="100%"
                height="150px"
                class="white--text"
                @click="elegirTiempoCierre(15)"
                >15 min</v-btn
              >
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                x-large
                color="orange"
                width="100%"
                height="150px"
                class="white--text"
                @click="elegirTiempoCierre(30)"
                >30 min</v-btn
              >
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                x-large
                color="red"
                width="100%"
                height="150px"
                class="white--text"
                @click="elegirTiempoCierre(60)"
                >1 hora</v-btn
              >
            </v-col>
            <v-col cols="12" md="3">
              <v-btn
                x-large
                color="black"
                width="100%"
                height="150px"
                class="white--text"
                @click="elegirTiempoCierre(60 * 6)"
                >6 horas</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import panelLateral from "@/components/panelLateral.vue";
import panelPedidosInternos from "@/components/pedidosInternos_panelLateral.vue";
import footerComponent from "@/components/footerComponent.vue";
import notificacionDialog from "@/components/NotificacionDialogo.vue";
import { mapState, mapActions } from "vuex";
import { arrayHorariosRest } from "../utils/dias.utils.js";
import moment from "moment";

export default {
  components: {
    panelLateral,
    panelPedidosInternos,
    footerComponent,
    notificacionDialog,
  },
  data() {
    return {
      loadingNeo: false,
      estado: {
        loading: false,
      },
      menu: false,
      general: true,
      online: true,
      recoger: true,
      menuNeo: false,
      menuDirectorio: false,
      tiempoForzadoCierreDialog: false,
      tiempoForzadoCierreModo: null, //domicilio, recoger, online
      tiempoForzadoCierreEleccion: null, //el tiempo elegido
    };
  },
  watch: {
    tiempoForzadoCierreDialog(v) {
      if (!v) {
        if (this.tiempoForzadoCierreEleccion == null) {
          this.$forceUpdate();
        }
      }
    },
  },
  computed: {
    fechaNeo() {
      return this.restObjBruto && this.restObjBruto.fechaActualizacionNeo
        ? moment(this.restObjBruto.fechaActualizacionNeo.toDate()).format(
            "HH:mm DD-MM-YYYY"
          )
        : "";
    },
    estadoDomicilio: {
      get() {
        return this.$store.state.domicilioActivado;
      },
      set(v) {},
    },
    estadoRecoger: {
      get() {
        return this.$store.state.recogidaActivada;
      },
      set(v) {},
    },
    estadoGeneral: {
      get() {
        return this.estadoDomicilio || this.estadoRecoger;
      },
      set(v) {},
    },
    horariosDomicilio() {
      const a = arrayHorariosRest(this.restObj);
      var items = "";
      if (Array.isArray(a) && Array.isArray(a[0])) {
        var items = a[0].map((rango) => rango.inicio + " - " + rango.fin);
      }

      return items;
    },
    horariosRecoger() {
      const a = arrayHorariosRest(this.restObj);
      var items = "";
      if (Array.isArray(a) && Array.isArray(a[1])) {
        var items = a[1].map((rango) => rango.inicio + " - " + rango.fin);
      }
      return items;
    },
    domicilioAbierto() {
      return this.restObj.horariosComputados[0];
    },
    recogidaAbierta() {
      return this.restObj.horariosComputados[1];
    },
    ...mapState({
      restObjBruto: (state) => state.restauranteObj,
      negocioObj: (state) => state.negocioObj,
    }),
    restObj() {
      return {
        ...this.restObjBruto,
        modoHorarioRecogerTiempoMostrar: moment
          .unix(this.restObjBruto.modoHorarioRecogerTiempo)
          .format("HH:mm"),
        modoHorarioDomicilioTiempoMostrar: moment
          .unix(this.restObjBruto.modoHorarioDomicilioTiempo)
          .format("HH:mm"),
      };
    },
    nombre() {
      return this.restObj.nombre;
    },
    horariosComputados() {
      return this.restObj.horariosComputados;
    },
  },
  methods: {
    actualizarNeoRestaurante() {
      var _vm = this;
      _vm.loadingNeo = true;

      this.$store
        .dispatch("neo/actualizarNeoRestaurante")
        .then(() => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Productos sincronizados",
          });
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al sincronizar " + e,
          });
        })
        .finally(() => {
          _vm.loadingNeo = false;
        });
    },
    cambiarEstadoDomicilio(v) {
      var _vm = this;
      this.$store.commit("setDomicilioActivado", v);
      this.$store
        .dispatch("setDomicilioActivado", {
          v: v,
          t: _vm.tiempoForzadoCierreEleccion,
        })
        .then(() => {
          this.$store.commit("setDomicilioActivado", v);
        })
        .catch((e) => {})
        .finally(() => {
          _vm.estado.loading = false;
        });
    },
    cambiarEstadoRecoger(v) {
      var _vm = this;
      this.$store.commit("setRecogidaActivada", v);
      this.$store
        .dispatch("setRecogidaActivada", {
          v: v,
          t: _vm.tiempoForzadoCierreEleccion,
        })
        .then(() => {
          this.$store.commit("setRecogidaActivada", v);
        })
        .catch((e) => {})
        .finally(() => {
          _vm.estado.loading = false;
        });
    },
    cambio(v) {
      this.tiempoForzadoCierreModo = v;
      var _vm = this;
      if (
        (v.includes("domicilio") && this.estadoDomicilio) ||
        (v.includes("recoger") && this.estadoRecoger)
      ) {
        // va a cerrar
        this.tiempoForzadoCierreEleccion = null;
        this.tiempoForzadoCierreDialog = true;
      } else {
        this.tiempoForzadoCierreEleccion = null;
        if (v.includes("domicilio")) {
          _vm.cambiarEstadoDomicilio(true);
        }
        if (v.includes("recoger")) {
          _vm.cambiarEstadoRecoger(true);
        }
      }
    },
    ...mapActions({
      logout: "auth/firebaseLogout",
    }),
    volver() {
      this.$router.go(-1);
    },
    elegirTiempoCierre(t) {
      this.tiempoForzadoCierreEleccion = t;
      this.tiempoForzadoCierreModo.forEach((m) => {
        if (m == "domicilio") this.cambiarEstadoDomicilio(false);
        if (m == "recoger") this.cambiarEstadoRecoger(false);
      });
      this.tiempoForzadoCierreModo = [];
      this.tiempoForzadoCierreDialog = false;
    },
  },
  mounted() {
    this.$store.dispatch("cargaDespuesInicio");
  },
};
</script>

<style>
.p-sticky {
  position: sticky !important;
  top: 75px !important;
}
</style>