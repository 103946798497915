// https://vuex.vuejs.org/en/mutations.html

export default {
  setLoaded(state, b) {
    state.loaded = b;
  },
  setRestauranteObj(state, rest) {
    state.restauranteObj = rest;
  },
  setNegocioObj(state, neg) {
    state.negocioObj = neg;
  },
  setTiempoDomicilio(state, v) {
    state.tiempoDomicilio = v;
  },
  setTiempoRecogida(state, v) {
    state.tiempoRecogida = v;
  },
  setRecogidaActivada(state, v) {
    state.recogidaActivada = v;
  },
  setDomicilioActivado(state, v) {
    state.domicilioActivado = v;
  },
  setModoDesarrollo(state, modo) {
    state.modoDesarrollo = modo;
  },
  setImpuestos(state, imp) {
    state.impuestos = imp;
  }
}
