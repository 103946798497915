<template>
  <!-- eslint-disable-next-line no-mixed-spaces-and-tabs -->
  <v-app>
    <v-snackbar v-model="esDesarrollo" left color="info" :timeout="0">
      <v-spacer></v-spacer>¡DESARROLLO!
      <v-spacer />
    </v-snackbar>
    <v-snackbar v-model="snackbar" :color="snackbar_color" top>
      {{ snackbar_text }}
      <v-btn text outlined @click="snackbar = false" color>Cerrar</v-btn>
    </v-snackbar>
    <template v-if="!isLoaded">
      <v-overlay opacity="0">
        <v-container class="d-flex align-center justify-center flex-column">
          <h1 class="text--grey grey--text text--darken-4 mb-10">
            Cargando...
          </h1>

          <v-progress-circular
            size="50"
            width="8"
            indeterminate
            color="grey darken-4"
          ></v-progress-circular>
        </v-container>
      </v-overlay>
    </template>
    <router-view v-else />
    <producto-pedir />
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { projectId } from "@/firebase";
import ProductoPedir from "./components/ProductoPedir.vue";
export default {
  name: "App",
  components: { ProductoPedir },
  data: () => ({
    //
  }),
  computed: {
    snackbar: {
      get() {
        return this.$store.state.snackbar.show;
      },
      set() {
        this.$store.commit("snackbar/reset");
      },
    },
    ...mapState({
      isLogged: (state) => state.auth.isLogged,
      //isLoaded: state => state.loaded,
      snackbar_text: (state) => state.snackbar.text,
      snackbar_color: (state) => state.snackbar.color,
    }),
    ...mapGetters({
      isLoaded: "isLoaded",
      esDesarrollo: "esDesarrollo",
    }),
    cargando: function () {
      let res = isLoaded;
      // (state.loaded && !isLogged) or (state.loaded && state.restauranteObj )

      return res;
    },
  },
  mounted() {
    if (projectId == "fun-eat") {
      this.$store.commit("setModoDesarrollo", "desarrollo");
    } else if (projectId == "fun-eat-produccion") {
      this.$store.commit("setModoDesarrollo", "produccion");
    }
  },
};
</script>

<style>
.border {
  border: 1px solid #000;
}
.borderB {
  border: 1px solid #fff;
}
</style>
