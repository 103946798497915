import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { db } from '@/firebase'
import firebase from 'firebase'
import MultiFiltersPlugin from './plugins/Multifilters'

Vue.config.productionTip = false
Vue.use(MultiFiltersPlugin);

firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    return db.collection('users').doc(user.uid).get()
      .then((doc) => {
        let user_db = {};
        if (doc.exists) {
          user_db = doc.data();
        }
        let user_combinado = { ...user, ...user_db };
        store.commit('auth/setUser', user_combinado);
        if (user_db.role != 'tpv') {
          router.push('/login')
          store.commit('setRestauranteObj', false)
          store.commit('setNegocioObj', false)
          store.commit('setLoaded', true);
        } else {
          return store.dispatch('inicioTpvAlternativo')
            .then(() => {
            })
        }
      })
      .finally(() => {
        store.commit('setLoaded', true);
      })
  } else {
    router.push('/login')
    store.commit('setLoaded', true);
  }
})


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
