export default {
    setMensajeSuccess(state,payload){
        state.text = payload.msj;
        state.color = 'success';
        state.show = true;
    },
    setMensajeDanger(state,payload){
        state.text = payload.msj;
        state.color = 'error';
        state.show = true;
    },
    setMensajeInfo(state,payload){
        state.text = payload.msj;
        state.color = 'info';
        state.show = true;
    },
    reset(state) {
        state.show = false,
        state.color = 'success',
        state.text = ''
    }
}