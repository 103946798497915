export default {
  setPedidos(state, p) {
    state.pedidosArray = p;
  },
  setPedidosDirectorioMatriz(state, p) {
    state.pedidosDirectorioMatriz = p;
  },
  setPedidoEditando(state, p) {
    state.pedidoEditando = p;
  }
}