import { estados } from '@/utils/estadosPedidos.utils.js'
export default {
    pedidosArray: [],
    pedidosDirectorioMatriz: [],
    estados: estados,
    formaPago: [{
        id: 'tarjeta_online',
        name: 'Tarjeta Online',
        modo: ['domicilio', 'recoger']
    }, {
        id: 'tarjeta_domicilio',
        name: 'Tarjeta a domicilio',
        modo: ['domicilio']

    }, {
        id: 'efectivo',
        name: 'Efectivo',
        modo: ['domicilio']
    }, {
        id: 'local',
        name: 'En local',
        modo: ['recoger']
    }
    ],
    cambioDisponible: [{
        id: 10,
        name: '10 €'
    }, {
        id: 20,
        name: '20 €'
    }, {
        id: 40,
        name: '40 €'
    }, {
        id: 50,
        name: '50 €'
    },
    {
        id: 100,
        name: '100 €'
    },
    ],
    estados: [
        { text: "Finalizado", value: "finalizado" },
        { text: "En Curso", value: "" }
    ],
    modos: [
        { text: "A recoger", value: "recoger" },
        { text: "A domicilio", value: "domicilio" }
    ],
    eliminarMotivos: [
        {
            text: 'Solicitado por dueño negocio',
            value: 'solicitado-negocio',
            icon: 'mdi-store'
        },
        {
            text: 'Solicitado por el usuario',
            value: 'solicitado-usuario',
            icon: 'mdi-account'
        },
        {
            text: 'Fallo de la aplicación',
            value: 'fallo-aplicacion',
            icon: 'mdi-bug'
        }
    ],
    pedidoEditando: null
}