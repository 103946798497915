<template>
  <v-content>
    <v-row id="productos">
      <!--<v-subheader>PEDIDO ID: {{pedidoEnCursoComprobar.numPedidoInternos}}</v-subheader>-->

      <v-col class="px-5" cols="12">
        <v-tabs
          v-model="eleccionCategoria"
          center-active
          color="dark"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
        >
          <v-tab
            active-class="activeTab"
            v-for="(item, i) in categorias"
            :value="item.id"
            :key="item.id"
            >{{ item.nombre }}</v-tab
          >
        </v-tabs>
      </v-col>

      <v-row>
        <v-col cols="8" class="mx-auto">
          <v-list>
            <v-list-item v-for="(p, i) in productos" :key="p.id">
              <v-list-item-title>{{ p.nombre }}</v-list-item-title>
              <v-list-item-action>
                <v-switch :value="p.asociado" />
              </v-list-item-action>
            </v-list-item> </v-list
        ></v-col>
      </v-row>

      <!-- <v-col class="d-none d-md-flex" cols="2">
        <v-card flat color="transparent" class width="100%">
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="d-flex align-center justify-center mb-2"
              width="100%"
              height="125px"
              :color="hover ? 'grey lighten-4' : 'white'"
              @mouseup="mouseDownToScroll = false"
              @mousedown="scrollTo('up')"
            >
              <v-icon size="40">mdi-chevron-up</v-icon>
            </v-card>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-card
              class="d-flex align-center justify-center mt-2"
              width="100%"
              height="125px"
              :color="hover ? 'grey lighten-4' : 'white'"
              @mouseup="mouseDownToScroll = false"
              @mousedown="scrollTo('down')"
            >
              <v-icon size="40">mdi-chevron-down</v-icon>
            </v-card>
          </v-hover>
        </v-card>
      </v-col> -->
    </v-row>
  </v-content>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { mostrarIdioma } from "@/utils/helper.utils.js";
export default {
  components: {},
  data() {
    return {
      eleccionCategoria: 0,
    };
  },
  computed: {
    eleccionCategoriaComputed() {
      return this.categoriasBruto &&
        this.categoriasBruto[this.eleccionCategoria]
        ? this.categoriasBruto[this.eleccionCategoria].id
        : "";
    },
    ...mapState({
      restauranteObj: "restauranteObj",
      categoriasBruto: (state) => state.productos.categorias,
      productosBruto: (state) => state.productos.productosArray,
    }),
    ...mapGetters({
      productosRest: "productos/PRODUCTOS_RESTAURANTE",
    }),
    categorias() {
      return this.categoriasBruto.map((c) => {
        return {
          ...c,
          nombre: mostrarIdioma(c.nombre),
        };
      });
    },
    productos() {
      console.log(this.eleccionCategoriaComputed);
      console.log(this.eleccionCategoria);
      return this.productosRest
        .filter((p) => p.categoria == this.eleccionCategoriaComputed)
        .map((p) => {
          return {
            ...p,
            nombre: mostrarIdioma(p.nombre),
          };
        });
    },
  },
  methods: {},
  mounted() {
    // this.$store.dispatch("productos/getProductos");
  },
};
</script>
	
<style scope>
</style>