<template>
  <v-toolbar flat class="mx-2" fluid>
    <!-- ############## NUEVO PEDIDO INTERNO  ############## -->
    <v-row v-if="$router.currentRoute.path.includes('/pedidos-internos/add')">
      <v-btn outlined left x-large @click="$router.push('/pedidos-internos')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Volver</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        left
        x-large
        @click="guardarPedidoInterno"
        :loading="$store.state.internos.pedidoGuardadoLoading"
        :disabled="$store.state.internos.pedidoGuardado"
      >
        <v-icon class="mr-4">mdi-content-save-outline</v-icon>
        <span>Guardar</span>
      </v-btn>
      <v-btn
        class="ml-4"
        outlined
        left
        x-large
        @click="enviarPedidoInterno"
        :loading="$store.state.internos.pedidoEnviarLoading"
        :disabled="$store.getters['internos/enviarButtonDisabled']"
      >
        <v-icon class="mr-4">mdi-send</v-icon>
        <span>Enviar</span>
      </v-btn>
    </v-row>
    <!-- ############## PEDIDOS INTERNOS ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/pedidos-internos'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4"
        outlined
        left
        x-large
        @click="$router.push('/pedidos-internos/add')"
      >
        <v-icon class="mr-4">mdi-content-save-outline</v-icon>
        <span>Nuevo</span>
      </v-btn>
    </v-row>
    <!-- ############## CLIENTES ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/clientes'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined left x-large @click="getClientes">
        <v-icon class="mr-4">mdi-account-convert</v-icon>
        <span>Cargar</span>
      </v-btn>
    </v-row>
    <!-- ############## PEDIDOS ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/pedidos'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <!-- ############## PRODUCTOS ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/productos'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>
    <!-- ############## GESTION INTERNA ############## -->
    <v-row v-else-if="$router.currentRoute.path == '/gestion-interna'">
      <v-btn outlined left x-large @click="$router.push('/dashboard')">
        <v-icon class="mr-4">mdi-arrow-left</v-icon>
        <span>Atrás</span>
      </v-btn>
      <v-spacer></v-spacer>
    </v-row>

    <!-- ############## OTROS  ############## -->
    <v-row v-else class="pa-3 d-flex flex-row-reverse">
      <span class="white--text">&copy; Mewo Systems 2020</span>
    </v-row>
  </v-toolbar>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    internosAdd() {
      return inc;
    },
    ...mapActions({
      getClientes: "clientes/getClientes",
    }),
    guardarPedidoInterno() {
      var _vm = this;
      this.$store.commit("internos/setPedidoGuardadoLoading", true);
      this.$store
        .dispatch("internos/savePedido")
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido guardado",
          });
          this.$store.commit("internos/setPedidoGuardado", true);
          this.$store.commit("internos/setPedidoEnviado", false);
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al guardar el pedido: " + e,
          });
        })
        .finally(() => {
          this.$store.commit("internos/setPedidoGuardadoLoading", false);
        });
    },
    enviarPedidoInterno() {
      var _vm = this;
      this.$store.commit("internos/setPedidoEnviarLoading", true);
      this.$store
        .dispatch("internos/enviarPedido")
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Pedido enviado",
          });
          this.$store.commit("internos/setPedidoEnviado", true);
          this.$router.push("/pedidos-internos");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al enviar el pedido: " + e,
          });
        })
        .finally(() => {
          this.$store.commit("internos/setPedidoEnviarLoading", false);
        });
    },
  },
  mounted() {},
  watch: {
    $route(to, from) {
      this.$forceUpdate();
      window.scrollTo(0, 0);
    },
  },
};
</script>
	
<style scope>
</style>