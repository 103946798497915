<template>
  <v-content>
    <v-row>
      <br />

      <v-col cols="12" md="6">
        <v-text-field
          disabled
          v-model="pedido.id"
          readonly
          label="ID"
          @change="cambio"
          :loading="loading"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :items="modos"
          v-model="pedido.modo"
          label="Modo"
          @change="cambio"
          :loading="loading"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="pedido.forma_pago == 'tarjeta_online'"
          @change="cambio"
          :loading="loading"
          :items="formasPagoComputada"
          v-model="pedido.forma_pago"
          item-text="name"
          item-value="id"
          label="Forma de pago"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-if="pedido.forma_pago == 'efectivo'"
          @change="cambio"
          :loading="loading"
          :items="cambioDisponible"
          v-model="pedido.cambio"
          item-text="name"
          item-value="id"
          label="Cambio"
        ></v-select>
      </v-col>
    </v-row>
    <!-- CARRITO -->
    <v-container class="pa-0 px-10">
      <!-- <v-btn x-large color="primary" @click="openDialogAdd"
        ><v-icon>mdi-plus</v-icon></v-btn
      > -->
      <h3>numero de productos {{ pedido.numeroProductos }}</h3>
      <v-row
        class="py-3 borderBottom"
        justify="center"
        no-gutters
        v-for="(prod, i) in pedido.productos"
        :key="i"
      >
        <v-col class="pa-0 ma-0" cols="2" style="max-width: 54px">
          <v-select
            style="margin-top: 4px !important"
            hide-details
            class="pa-0 ma-0 font-weight-medium"
            :items="cantidadSelect(prod.cantidad)"
            v-model="prod.cantidad"
            :key="prod.nombre"
            item-text="text"
            item-value="val"
            readonly
            :menu-props="{ maxHeight: 'auto' }"
            @change="cambio"
          >
            <template slot="append">
              <v-icon class="subtitle-1 lighten-4--text mt-1"
                >mdi-chevron-down</v-icon
              >
            </template>
          </v-select>
          <!-- <v-list-item-title class="mt-2 font-weight-medium" v-else>
            {{ prod.cantidad.toString() }}
            <span class="grey--text font-weight-regular">x</span> 
          </v-list-item-title>-->
        </v-col>
        <v-col cols class="pl-4 py-2 text-truncate">
          <v-list-item-title class="font-weight-medium">{{
            prod.nombre
          }}</v-list-item-title>
          <v-list-item-subtitle
            class
            v-for="(opcion, i) in prodOpcionesNameComputado(prod.opcionesName)"
            :key="i"
            >{{ opcion.cantidad > 1 ? opcion.cantidad + "x" : "--" }}
            {{ opcion.key }}</v-list-item-subtitle
          >
        </v-col>
        <v-col cols class style="max-width: 76px">
          <v-list-item-title class="text-end py-2 font-weight-medium text-right"
            >{{
              replaceComa(
                (
                  prod.cantidad *
                  (sumaOpciones(prod.opcionesPrecio) + parseFloat(prod.precio))
                ).toFixed(2)
              )
            }}
            €</v-list-item-title
          >
        </v-col>
      </v-row>
      <v-row
        v-if="
          pedido.modo == 'domicilio' &&
          pedido.recargo &&
          pedido.recargo > 0 &&
          pedido.precioTotal > 0
        "
      >
        <v-col cols class="pl-4 py-2 text-truncate">
          <v-list-item-title class="font-weight-medium"
            >Recargo</v-list-item-title
          >
          <v-list-item-subtitle class
            >Por servicio a domicilio</v-list-item-subtitle
          >
        </v-col>
        <v-col cols class style="max-width: 76px">
          <v-list-item-title class="text-end py-2 font-weight-medium"
            >{{ replaceComa(pedido.recargo.toFixed(2)) }} €</v-list-item-title
          >
        </v-col>
      </v-row>

      <v-list class="h-100" color="transparent">
        <!-- Total y Subtotal -->
        <!--<v-list-item class="ma-0 pa-0">
        <v-row class no-gutters>
          <v-col offset="0" cols="12" class="mb-4 mt-2">
            <v-text-field hide-details outlined label="Código de descuento"></v-text-field>
          </v-col>
        </v-row>
      </v-list-item>-->
        <v-divider></v-divider>
        <v-list-item class="ma-0 pa-0">
          <v-row no-gutters class="grey--text">
            <v-col cols="6">Subtotal</v-col>
            <v-col
              class="nowrap_space d-flex justify-end ma-0 grey--text"
              cols="6"
              >{{
                calculoPrecioTotal != undefined
                  ? (calculoPrecioTotal - calculoImpuestosCarrito).toFixed(2)
                  : "0.00"
              }}
              €</v-col
            >
          </v-row>
        </v-list-item>
        <v-list-item class="ma-0 pa-0">
          <v-row no-gutters class="grey--text">
            <v-col cols="6" class="pa-0 ma-0">{{ getImpuestoTexto }}</v-col>
            <v-col
              class="nowrap_space d-flex justify-end ma-0 grey--text pa-0 ma-0"
              cols="6"
              >{{
                calculoPrecioTotal != undefined
                  ? calculoImpuestosCarrito.toFixed(2)
                  : "0.00"
              }}
              €</v-col
            >
          </v-row>
        </v-list-item>

        <v-list-item class="headline pa-0 ma-0">
          <v-row no-gutters class="headline pb-4">
            <v-col cols="6">Total</v-col>
            <v-col
              class="nowrap_space d-flex justify-end ma-0 headline"
              cols="6"
              >{{
                calculoPrecioTotal != undefined
                  ? calculoPrecioTotal.toFixed(2)
                  : "0.00"
              }}
              €</v-col
            >
          </v-row>
        </v-list-item>
      </v-list>
    </v-container>
  </v-content>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      cantidadProducto: [
        { val: 0, text: "Eliminar" },
        { val: 1, text: "1x" },
        { val: 2, text: "2x" },
        { val: 3, text: "3x" },
        { val: 4, text: "4x" },
        { val: 5, text: "5x" },
        { val: 6, text: "6x" },
        { val: 7, text: "7x" },
        { val: 8, text: "8x" },
        { val: 9, text: "9x" },
        { val: 10, text: "10x" },
      ],
      pedido: {
        id: null,
        nombre: "sin nombre",
        modo: "",
        cambio: "",
        forma_pago: "",
        precio_total: 0,
        productos: [],
      },
      loading: false,
    };
  },
  computed: {
    calculoImpuestosCarrito() {
      let precio = 0;
      this.pedido.productos.forEach((e) => {
        let impuesto = e.impuesto;
        let opciones = e.opcionesPrecio;
        let precioOpciones = 0;

        if (opciones.length > 0) {
          const reducer = (accumulator, currentValue) =>
            accumulator + currentValue;
          precioOpciones = opciones.reduce(reducer);
        }
        let precioProducto =
          (parseFloat(e.precio) + precioOpciones) * e.cantidad;
        let base = precioProducto / (1 + parseFloat(impuesto));
        precio += precioProducto - base;
      });
      return precio;
    },
    calculoPrecioTotal() {
      return this.pedido.precioTotal;
    },
    getImpuestoTexto() {
      const impuestos = this.$store.state.impuestos;
      const restObj = this.$store.state.restauranteObj;
      var found = impuestos.find((i) => i.id == restObj.impuesto);
      if (found) {
        return found.nombre.replace(/ .*/, "");
      } else {
        return "";
      }
    },
    ...mapState({
      formasPago: (state) => state.pedidos.formaPago,
      estados: (state) => state.pedidos.estados,
      modos: (state) => state.pedidos.modos,
      cambioDisponible: (state) => state.pedidos.cambioDisponible,
      productoPidiendo: (state) => state.productos.productoPidiendo,
      productosPidiendo: (state) => state.productos.productosPidiendo,
      categoriasPidiendo: (state) => state.productos.categoriasPidiendo,
    }),
    ...mapGetters({
      pedidos: "clientes/clientesPedidosListado",
    }),
    formasPagoComputada() {
      return this.formasPago
        .filter((f) => f.modo.includes(this.pedido.modo))
        .map((f) => {
          return {
            ...f,
            name:
              f.id == "tarjeta_online"
                ? f.name + " (contacte con soporte)"
                : f.name,
            disabled: f.id == "tarjeta_online",
          };
        });
      /*return this.formasPago.map((f) => !f.modo.includes(this.pedido.modo))
        return {
          ...f,
          name: !f.modo.includes(this.pedido.modo)
            ? f.name + " (solo " + f.modo + ")"
            : f.name,
          disabled:
            !f.modo.includes(this.pedido.modo) || f.id == "tarjeta_online",
        };
      });*/
    },
  },

  methods: {
    openDialogAdd() {
      this.$store.commit("pedidos/setPedidoEditando", this.pedido);
      this.$store.commit("productos/setPidiendoDialog", true);
    },
    changeQuantity(cant, id, indexArray) {
      const _vue = this;
      let payload = { id: id, cant: parseInt(cant), indexArray: indexArray };
      // _vue.$store.commit("pedido/SET_LOADING_QUANTITY", true);
      // _vue.$store
      //   .dispatch("pedido/SET_CANTIDAD_PRODUCTO", payload)
      //   .then(function () {})
      //   .catch(function () {})
      //   .finally(function () {
      //     _vue.$store.commit("pedido/SET_LOADING_QUANTITY", false);
      //   });
    },
    cantidadSelect(num) {
      let res = this.cantidadProducto;
      let temp = [{ val: num.toString(), text: num.toString() + "x" }];
      if (num > res.length - 1) {
        return res.concat(temp);
      } else {
        return res;
      }
    },
    prodOpcionesNameComputado(opcionesName) {
      var res = [];
      opcionesName.forEach((op) => {
        var index = res.findIndex((o) => o.key == op);
        if (index < 0) {
          res.push({
            key: op,
            cantidad: 1,
          });
        } else {
          res[index].cantidad++;
        }
      });
      return res;
    },
    breadcrumbOpciones(opciones) {
      let bread = "";
      opciones.forEach(function (e, i) {
        bread += e;
        i != opciones.length - 1 ? (bread += " / ") : "";
      });
      return bread;
    },
    replaceComa(str) {
      return str.toString().trim().replace(".", ",");
    },
    sumaOpciones(array) {
      if (array.length > 0) {
        const reducer = (accumulator, currentValue) =>
          accumulator + currentValue;
        return array.reduce(reducer);
      } else {
        return 0;
      }
    },
    cambio() {
      let _vm = this;
      _vm.loading = true;
      _vm.$store
        .dispatch("pedidos/cambiarPedido", this.pedido)
        .then((r) => {
          this.$store.commit("snackbar/setMensajeSuccess", {
            msj: "Se ha editado el pedido",
          });
        })
        .catch((e) => {
          this.$store.commit("snackbar/setMensajeDanger", {
            msj: "Error al editar el pedido: " + e,
          });
        })
        .finally(() => {
          _vm.loading = false;
          this.getPedido(_vm.pedido.id);
        });
    },
    getPedido(id) {
      this.$store
        .dispatch("pedidos/getPedido", id)
        .then((r) => {
          this.pedido = r;
        })
        .catch(() => {
          this.$router.push("/pedidos");
        });
    },
  },
  mounted() {
    const id = this.$route.params.idpedido;
    this.getPedido(id);
  },
};
</script>