<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="notificaciones"
          item-key="name"
          :loading="loading"
          :search="search"
          sort-by="numPedidoInternos"
          :sort-desc="true"
          class="elevation-1"
        >
          <template v-slot:item.acciones="{ item }">
            <v-row class="d-flex flex-row-reverse">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="px-2 mr-1"
                    color="black"
                    min-width="0"
                    icon
                    v-on="on"
                    @click="lanzarNot(item)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver notificación</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      loading: false,
      filters: {
        fechaFilter: "",
        envioFilter: "",
        estadoFilter: "",
        restFilter: "",
      },
      headers: [
        { text: "Titulo", value: "tituloMostrar", width: "40%" },
        {
          text: "Fecha Inicio",
          align: "start",
          sortable: true,
          value: "fechaMostrar",
          width: "40%",
        },
        {
          sortable: false,
          text: "Acciones",
          value: "acciones",
          align: "right",
          width: "15%",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      notificacionesBruto: (state) =>
        state.notificaciones.notificacionesListado,
    }),
    notificaciones() {
      return this.notificacionesBruto
        .sort((a, b) => b.hora.seconds - a.hora.seconds)
        .map((n) => {
          return {
            ...n,
            tituloMostrar: n.titulo,
            fechaMostrar: moment(n.hora.toDate()).format("HH:mm DD-MM-YYYY"),
            visualizar: true,
          };
        });
    },
    ...mapGetters({
      //notificaciones: "notificaciones/notificacionesListado",
    }),
    search() {
      let res = "";
      return res;
      for (let item in this.filters) {
        res += item;
      }
      return res;
    },
  },
  methods: {
    lanzarNot(not) {
      this.$store.commit("notificaciones/setNotificacionLanzar", not);
    },
    getNotificaciones() {
      var _vm = this;
      _vm.loading = true;
      this.$store
        .dispatch("notificaciones/getNotificacionesListado")
        .then(() => {})
        .finally(() => {
          _vm.loading = false;
        });
    },

    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        if (a.estado.value == "realizando") {
          return -1;
        }
        if (isDescending) {
          return moment(b.fechaEspera) - moment(a.fechaEspera);
        } else {
          return moment(a.fechaEspera) - moment(b.fechaEspera);
        }
      });

      return items;
    },
  },
  mounted() {
    this.getNotificaciones();
  },
};
</script>
	
<style scope>
.border {
  border: 1px solid #000;
}
</style>